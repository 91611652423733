import Footer from "../components/Footer";
import Header from "../components/Header";
import { useTranslation } from 'react-i18next';
export default function About() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="relative bg-primary-main overflow-hidden">
        <div className="about-sh-1">
          <div className="max-w-7xl mx-auto pb-28 mt-10 px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-18 lg:px-8 xl:mt-20">
            <div className="grid grid-cols-1 lg:grid-cols-3">
              <div className="col-span-1">
                <div className="sm:text-center lg:text-left">
                  <h1 className="text-white text-5xl font-semibold">
                    {t('about.title')}
                  </h1>
                </div>
              </div>
              <div className="col-span-2">
                <p className="mt-3 text-2xl font-semibold text-white">
                  {t('about.subtitle')}
                </p>
                <p className="mt-5 text-lg font-light text-white">
                  {t('about.description')}
                </p>
              </div>
            </div>
          </div>
          <div className="homeSlider2bg py-16">
            <div className="max-w-7xl mx-auto text-center">
              <h2 className="font-semibold text-4xl text-dark-main">
                {t('about.band.one')}{" "}
                <span className="bg-linear-variant-5 bg-clip-text text-fill-color-transparent">
                  {t('about.band.two')}{" "}
                </span>
                {t('about.band.three')}
              </h2>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="py-16">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-14 px-4 sm:px-6 lg:px-8">
          <div className="lg:hidden mx-auto">
            <img src="/Saly-31.png" alt="asd" />
          </div>
          <div>
            <h2 className="font-semibold text-4xl text-dark-main">
              {t('about.section.title')}
            </h2>
            <p className="mt-5 text-xl">
              {t('about.section.description')}
            </p>
            <div className="grid grid-cols-2 gap-14 mt-32">
              <div>
                <h3 className="font-semibold text-xl text-dark-main mb-5">
                  {t('about.section.one.title')}
                </h3>
                <p className="font-light text-lg text-dark-main">
                  {t('about.section.one.description')}
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl text-dark-main mb-5">
                  {t('about.section.two.title')}
                </h3>
                <p className="font-light text-lg text-dark-main">
                  {t('about.section.two.description')}
                </p>
              </div>
            </div>
          </div>
          <div className="hidden lg:block mx-auto">
            <img src="/Saly-31.png" alt="asd" />
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

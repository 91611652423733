import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'tr',
        resources: {
            tr: {
                translation: {
                    home:{
                        section_one:{
                            title:"Bugün Bendensin Sevdiklerin doya doya yesin, içsin, eğlensin!",
                            description:"Türkiye’nin ısmarlama ve hediye uygulaması Bugün Bendensin'le arkadaşlarınıza istediğiniz her yerden, istediğiniz her şeyi ısmarlayabilirsiniz."
                        },
                        section_two:{
                            title:"Nasıl Çalışır?",
                            description:"Uygulamanın nasıl çalıştığını 3 adımda görebilirsiniz.",
                            one:"İstediğin markadan istediğin ürünü seç.",
                            two:"Hediye etmek istediğin arkadaşlarınla kodu paylaş.",
                            three:"Arkadaşın istediği zaman ısmarladığın hediyeni alsın.",
                            /* four:"Karekodu paylaşmak için whatsapp, copylink ve diğer sosyal medya ağlarını da kullanabilirsiniz.",
                            five:"Ismarladığınız kişi bu karekodu mağaza görevlisine okutup hediyesini alabilir." */
                        },
                       /*  section_three:{
                            title:"Kampanyalar"
                        }, */
                        section_sss:{
                            title:"Sıkça Sorulan Sorular",
                            one:{
                                title:"Nasıl Üye Olurum?",
                                description:"Uygulama giriş ekranındaki ‘’kayıt ol’’ butonuna tıklayarak üyelik formunu doldurup uygulamamıza üye olabilirsiniz."
                            },
                            two:{
                                title:"Bugün Bendensin ‘de ödeme seçenekleri nelerdir?",
                                description:"Online kredi kartı ile kredi kartı numaranızı ve CVV bilgisini girerek ödeme yapabilirsiniz"
                            },
                            three:{
                                title:"Ismarladığım kişiye notum var. Nasıl ekleyebilirim?",
                                description:"Ismarlamak istediğiniz ürünleri seçip sepetinize ekledikten sonra, sepette sipariş detaylarının hemen altında bulunan ‘’not eklemek ister misiniz?’’ kutucuğuna iletmek istediğiniz notunuzu yazabilirsiniz."
                            },
                            four:{
                                title:"Ismarlanan ürün kaç gün içerisinde kullanılabilir?",
                                description:"Ismarladığınız kişi tarafından 15 gün içinde kullanılabilir."
                            },
                            see_all_btn:"Tümünü Gör"
                        }
                    },
                    about:{
                      title:"Hakkımızda",
                      subtitle:"Bugün bendensin, anlaşmalı mağazalarımızdaki yüzlerce ürün arasından, seçtiğiniz bir ürünü, ailenize, arkadaşınıza, değer verdiğiniz herkese ısmarlayabileceğiniz bir mobil uygulamadır.",
                      description:"Hediyeleşmek ve sevdiklerimize bir şeyler ısmarlamak kültürümüzün en belirgin ve yaygın özelliklerinden biridir. Dolayısıyla bu uygulama için, Türk insanının ısmarlama geleneğinin dijital ortamda zamandan tasarruf ettiren bir hali diyebiliriz.",
                      band:{
                          one:"Kayıtlı",
                          two:"150,000",
                          three:"Kişi Bugün Bendensin Kullanıyor."
                      },
                     
                      /* section:{
                         title:"Tristique condimentum urna volutpat eget eget sit.",
                        description:"Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.", 
                        one:{
                            title:"Vizyon",
                            description:"Türkiye ve benzer coğrafyalara özgü ''ısmarlama'' ve ''jest' kültürünü sahiplenmek, bu içgörüye dayalı fırsatları kolaylaştırmak ve erişilebilir kılmak." 
                        },
                          two:{
                            title:"Misyon",
                              description:"Müşterilerimize kaliteli, hızlı ve güvenilir ve eğlenceli deneyimler sunarak, işletmelere ve tüketicilere değer katmayı hedefliyoruz. Teknolojiyi kullanarak, yerel işletmelerin büyümesini destekliyor, kullanıcılarımıza geniş bir ürün yelpazesi sunuyor ve güvenli bir çevrimiçi platform sağlıyoruz. Aynı zamanda sürdürülebilirlik ve toplumsal sorumluluk ilkelerine bağlı kalarak, topluluklarımıza katkıda bulunuyoruz. "
                          }
                      } */
                    },

                    sozlesme:{
                        title:"Gizlilik Sözleşmesi",
                        subtitle:"Bugün bendensin, anlaşmalı mağazalarımızdaki yüzlerce ürün arasından, seçtiğiniz bir ürünü, ailenize, arkadaşınıza, değer verdiğiniz herkese ısmarlayabileceğiniz bir mobil uygulamadır.",
                        description:"Hediyeleşmek ve sevdiklerimize bir şeyler ısmarlamak kültürümüzün en belirgin ve yaygın özelliklerinden biridir. Dolayısıyla bu uygulama için, Türk insanının ısmarlama geleneğinin dijital ortamda zamandan tasarruf ettiren bir hali diyebiliriz.",
                        
                        /* section:{
                           title:"Tristique condimentum urna volutpat eget eget sit.",
                          description:"Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.", 
                          one:{
                              title:"Vizyon",
                              description:"Türkiye ve benzer coğrafyalara özgü ''ısmarlama'' ve ''jest' kültürünü sahiplenmek, bu içgörüye dayalı fırsatları kolaylaştırmak ve erişilebilir kılmak." 
                          },
                            two:{
                              title:"Misyon",
                                description:"Müşterilerimize kaliteli, hızlı ve güvenilir ve eğlenceli deneyimler sunarak, işletmelere ve tüketicilere değer katmayı hedefliyoruz. Teknolojiyi kullanarak, yerel işletmelerin büyümesini destekliyor, kullanıcılarımıza geniş bir ürün yelpazesi sunuyor ve güvenli bir çevrimiçi platform sağlıyoruz. Aynı zamanda sürdürülebilirlik ve toplumsal sorumluluk ilkelerine bağlı kalarak, topluluklarımıza katkıda bulunuyoruz. "
                            }
                        } */
                      },
                    
                        /* campaigns:{
                            title:"Kampanyalar",
                            description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci nulla interdum consectetur diam tincidunt elementum tristique.",
                        }, */
                   
                    faq:{
                        title:"Sıkça Sorulan Sorular",
                        description:"Bugün Bendensin hakkındaki sık sorulan sorulara buradan ulaşabilirsiniz.",
                        one:{
                            title:"Bugün Bendensin nedir?",
                            description:"Bugün bendensin, anlaşmalı mağazalarımızdaki yüzlerce ürün arasından, seçtiğiniz bir ürünü, ailenize, arkadaşınıza, değer verdiğiniz herkese ısmarlayabileceğiniz bir mobil uygulamadır."
                        },
                        two:{
                            title:"Bugün Bendensin nasıl çalışır?",
                            description:"•\tAnlaşmalı mağazalarımızdan dilediğiniz ürünü seçin\n" +
                                "                      •\tIsmarlayacağınız kişinin telefon numarasını girin veya rehberinizden seçin.\n" +
                                "                      •\tÖdemenizi yaptıktan sonra ısmarladığınız kişiye sms yolu ile bir karekod gideceketir.\n" +
                                "                      •\tKarekodu paylaşmak için vhatsapp, copylink ve diğer sosyal medya ağlarını da kullanabilirsiniz.\n" +
                                "                      •\tIsmarladığınız kişi bu karekodu mağaza görevlisine okutup hediyesini alabailir."
                        },
                        three:{
                            title:"Nasıl üye olurum?",
                            description:"Uygulama giriş ekranındaki ‘’kayıt ol’’ butonuna tıklayarak\n" +
                                "                      üyelik formunu doldurup uygulamamıza üye olabilirsiniz."
                        },
                        four:{
                            title:"Bugün Bendensin‘de ödeme seçenekleri nelerdir?",
                            description:"Onine kredi kartı ile kredi kartı numaranızı ve CVV bilgisini girerek ödeme yapabilirsiniz."
                        },
                        five:{
                            title:"Ismarladığım kişiye notum var. Nasıl ekleyebilirim?",
                            description:"Ismarlamak istediğiniz ürünleri seçip sepetinize ekledikten sonra, \n" +
                                "                      sepette sipariş detaylarının hemen altında bulunan ‘’not eklemek ister misiniz?’’ \n" +
                                "                      kutucuğuna iletmek istediğiniz notunuzu yazabilirsiniz."
                        },
                        six:{
                            title:"Ismarlanan ürün kaç gün içerisinde kullanılabilir?",
                            description:"Ismarladığınız kişi tarafından 15 gün içinde kullanılabilir."
                        },
                        seven:{
                            title:"Ismarladığım ürün nasıl teslim edilecek?",
                            description:"Cep telefonunuza gelen karekod ile ilgili mağazaya ulaştıktan sonra, \n" +
                                "                      mağaza görevlisine karekodu okutup hediyenizi alabilirsiniz."
                        },
                        eight:{
                            title:"Siparişlerimi nereden görebilirim?",
                            description:"Siparişlerinizi ‘’Siparişlerim’’ sayfasından takip edebilirsiniz."
                        },
                        nine:{
                            title:"Siparişimin kullanılıp kullanılmadığını görebilir miyim?",
                            description:"‘’ Siparişlerim’’ sayfasından ürününüzün kullanılıp kullanılmadığını takip edebilirsiniz."
                        },
                        ten:{
                            title:"15 gün içinde ürünü kullanmak için vaktim olmadı. Süreyi uzatabilir miyim?",
                            description:"Ücret dahilinde alabileceğiniz hediye kuponu ile ürünü edinme sürenizi uzatabilirsiniz."
                        },
                        eleven:{
                            title:"Hediye gönderdiğim numarayı yanlış yazdım, ne yapmalıyım?",
                            description:"Bizimle iletişime geçerek düzeltilmesini talep edebilir ya da iptal et butonunu kullanarak siparişinizi iptal edip\n" +
                                "                      tekrar doğru numaraya ısmarlama yapabilirsiniz."
                        },
                        twelve:{
                            title:"Yurt dışında da uygulama kullanılabiliyor mu?",
                            description:"Türkiye içi mağazalardan sevdiklerinize yurt dışından ısmarlama yapabilirsiniz."
                        },
                    },
                    contact:{
                        title:"İletişim",
                        description:"Tüm soru ve önerileriniz için bizimle iletişime geçin.",
                        form:{
                            one:"Ad Soyad",
                            two:"E-Posta",
                            three:"Konu",
                            four:"Bizimle ne için iletişime geçiyorsunuz?",
                            btn:"Gönder"
                        },
                        band:{
                            one:"Arayın",
                            two:"Email Gönderin",
                            three:"Ziyaret Edin"
                        }
                    },
                    header:{
                      one:"Anasayfa",
/*                       two:"Kampanyalar",
 */                      three:"Hakkımızda",
                      four:"SSS",
                      five:"İletişim"
                    },
                    footer: {
                        column_one:{
                            title:"Hakkımızda",
                            one:"Biz Kimiz"
                        },
                        column_two:{
                            title:"Yasal",
                            one:"Gizlilik Sözleşmesi",
                            //two:"Şartlar & Koşullar",
                            three:"Çerez Politikası"
                        },
                        column_three:{
                            title:"İletişim",
                            one:"S.S.S",
                            two:"Destek Masası",
                            three:"İletişim"
                        },
                        column_four:{
                            title:"İndirme"
                        }
                    }
                }
            },
            en: {
                translation: {
                    home:{
                        section_one:{
                            title:"English Title",
                            description:"Türkiye’nin en cömert uygulaması Bugün Bendensin anlaşmalı mağazalarımızdan seçeceğiniz ürünleri sevdiklerinize ısmarlayabilmeniz için sizi bekliyor."
                        },
                        section_two:{
                            title:"Nasıl Çalışır?",
                            description:"Uygulamanın nasıl çalıştığını 5 adımda görebilirsiniz.",
                            one:"Anlaşmalı mağazalarımızdan dilediğiniz ürünü seçin.",
                            two:"Ismarlayacağınız kişinin telefon numarasını girin veya rehberinizden seçin.",
                            three:"Ödemenizi yaptıktan sonra ısmarladığınız kişiye sms yolu ile bir karekod gideceketir.",
                            four:"Karekodu paylaşmak için whatsapp, copylink ve diğer sosyal medya ağlarını da kullanabilirsiniz.",
                            five:"Ismarladığınız kişi bu karekodu mağaza görevlisine okutup hediyesini alabilir."
                        },
                        section_three:{
/*                             title:"Kampanyalar"
 */                        },
                        section_sss:{
                            title:"Sıkça Sorulan Sorular",
                            one:{
                                title:"Nasıl Üye Olurum?",
                                description:"Uygulama giriş ekranındaki ‘’kayıt ol’’ butonuna tıklayarak üyelik formunu doldurup uygulamamıza üye olabilirsiniz."
                            },
                            two:{
                                title:"Bugün Bendensin ‘de ödeme seçenekleri nelerdir?",
                                description:"Online kredi kartı ile kredi kartı numaranızı ve CVV bilgisini girerek ödeme yapabilirsiniz"
                            },
                            three:{
                                title:"Ismarladığım kişiye notum var. Nasıl ekleyebilirim?",
                                description:"Ismarlamak istediğiniz ürünleri seçip sepetinize ekledikten sonra, sepette sipariş detaylarının hemen altında bulunan ‘’not eklemek ister misiniz?’’ kutucuğuna iletmek istediğiniz notunuzu yazabilirsiniz."
                            },
                            four:{
                                title:"Ismarlanan ürün kaç gün içerisinde kullanılabilir?",
                                description:"Ismarladığınız kişi tarafından 15 gün içinde kullanılabilir."
                            },
                            see_all_btn:"Tümünü Gör"
                        }
                    },
                    about:{
                        title:"Hakkımızda",
                        subtitle:"Bugün bendensin, anlaşmalı mağazalarımızdaki yüzlerce ürün arasından, seçtiğiniz bir ürünü, ailenize, arkadaşınıza, değer verdiğiniz herkese ısmarlayabileceğiniz bir mobil uygulamadır.",
                        description:"Hediyeleşmek ve sevdiklerimize bir şeyler ısmarlamak kültürümüzün en belirgin ve yaygın özelliklerinden biridir. Dolayısıyla bu uygulama için, Türk insanının ısmarlama geleneğinin dijital ortamda zamandan tasarruf ettiren bir hali diyebiliriz.",
                        band:{
                            one:"Kayıtlı",
                            two:"150,000",
                            three:"Kişi Bugün Bendensin Kullanıyor."
                        },
                        section:{
                            title:"Tristique condimentum urna volutpat eget eget sit.",
                            description:"Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.",
                            one:{
                                title:"Arcu velit etiam in.",
                                description:"Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo"
                            },
                            two:{
                                title:"Mattis tristique.",
                                description:"Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit"
                            }
                        }
                    },
                    /* campaigns:{
                        title:"Kampanyalar",
                        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci nulla interdum consectetur diam tincidunt elementum tristique.",
                    }, */
                    faq:{
                        title:"Sıkça Sorulan Sorular",
                        description:"Bugün Bendensin hakkındaki sık sorulan sorulara buradan ulaşabilirsiniz.",
                        one:{
                            title:"Bugün Bendensin nedir?",
                            description:"Bugün bendensin, anlaşmalı mağazalarımızdaki yüzlerce ürün arasından, seçtiğiniz bir ürünü, ailenize, arkadaşınıza, değer verdiğiniz herkese ısmarlayabileceğiniz bir mobil uygulamadır."
                        },
                        two:{
                            title:"Bugün Bendensin nasıl çalışır?",
                            description:"•\tAnlaşmalı mağazalarımızdan dilediğiniz ürünü seçin\n" +
                                "                      •\tIsmarlayacağınız kişinin telefon numarasını girin veya rehberinizden seçin.\n" +
                                "                      •\tÖdemenizi yaptıktan sonra ısmarladığınız kişiye sms yolu ile bir karekod gideceketir.\n" +
                                "                      •\tKarekodu paylaşmak için vhatsapp, copylink ve diğer sosyal medya ağlarını da kullanabilirsiniz.\n" +
                                "                      •\tIsmarladığınız kişi bu karekodu mağaza görevlisine okutup hediyesini alabailir."
                        },
                        three:{
                            title:"Nasıl üye olurum?",
                            description:"Uygulama giriş ekranındaki ‘’kayıt ol’’ butonuna tıklayarak\n" +
                                "                      üyelik formunu doldurup uygulamamıza üye olabilirsiniz."
                        },
                        four:{
                            title:"Bugün Bendensin‘de ödeme seçenekleri nelerdir?",
                            description:"Onine kredi kartı ile kredi kartı numaranızı ve CVV bilgisini girerek ödeme yapabilirsiniz."
                        },
                        five:{
                            title:"Ismarladığım kişiye notum var. Nasıl ekleyebilirim?",
                            description:"Ismarlamak istediğiniz ürünleri seçip sepetinize ekledikten sonra, \n" +
                                "                      sepette sipariş detaylarının hemen altında bulunan ‘’not eklemek ister misiniz?’’ \n" +
                                "                      kutucuğuna iletmek istediğiniz notunuzu yazabilirsiniz."
                        },
                        six:{
                            title:"Ismarlanan ürün kaç gün içerisinde kullanılabilir?",
                            description:"Ismarladığınız kişi tarafından 15 gün içinde kullanılabilir."
                        },
                        seven:{
                            title:"Ismarladığım ürün nasıl teslim edilecek?",
                            description:"Cep telefonunuza gelen karekod ile ilgili mağazaya ulaştıktan sonra, \n" +
                                "                      mağaza görevlisine karekodu okutup hediyenizi alabilirsiniz."
                        },
                        eight:{
                            title:"Siparişlerimi nereden görebilirim?",
                            description:"Siparişlerinizi ‘’Siparişlerim’’ sayfasından takip edebilirsiniz."
                        },
                        nine:{
                            title:"Siparişimin kullanılıp kullanılmadığını görebilir miyim?",
                            description:"‘’ Siparişlerim’’ sayfasından ürününüzün kullanılıp kullanılmadığını takip edebilirsiniz."
                        },
                        ten:{
                            title:"15 gün içinde ürünü kullanmak için vaktim olmadı. Süreyi uzatabilir miyim?",
                            description:"Ücret dahilinde alabileceğiniz hediye kuponu ile ürünü edinme sürenizi uzatabilirsiniz."
                        },
                        eleven:{
                            title:"Hediye gönderdiğim numarayı yanlış yazdım, ne yapmalıyım?",
                            description:"Bizimle iletişime geçerek düzeltilmesini talep edebilir ya da iptal et butonunu kullanarak siparişinizi iptal edip\n" +
                                "                      tekrar doğru numaraya ısmarlama yapabilirsiniz."
                        },
                        twelve:{
                            title:"Yurt dışında da uygulama kullanılabiliyor mu?",
                            description:"Türkiye içi mağazalardan sevdiklerinize yurt dışından ısmarlama yapabilirsiniz."
                        },
                    },
                    contact:{
                        title:"İletişim",
                        description:"Tüm soru ve önerileriniz için bizimle iletişime geçin.",
                        form:{
                            one:"Ad Soyad",
                            two:"E-Posta",
                            three:"Konu",
                            four:"Bizimle ne için iletişime geçiyorsunuz?",
                            btn:"Gönder"
                        },
                        band:{
                            one:"Arayın",
                            two:"Email Gönderin",
                            three:"Ziyaret Edin"
                        }
                    },
                    header:{
                        one:"Anasayfa",
/*                         two:"Kampanyalar",
 */                        three:"Hakkımızda",
                        four:"SSS",
                        five:"İletişim"
                    },
                    footer: {
                        column_one:{
                            title:"Hakkımızda",
                            one:"Biz Kimiz"
                        },
                        column_two:{
                            title:"Yasal",
                            one:"Gizlilik Sözleşmesi",
                            //two:"Şartlar & Koşullar",
                            three:"Çerez Politikası"
                        },
                        column_three:{
                            title:"İletişim",
                            one:"S.S.S",
                            two:"Destek Masası",
                            three:"İletişim"
                        },
                        column_four:{
                            title:"İndirme"
                        }
                    }
                }
            },
            ar: {
                translation: {
                    home:{
                        section_one:{
                            title:"عنوان عربي",
                            description:"Türkiye’nin en cömert uygulaması Bugün Bendensin anlaşmalı mağazalarımızdan seçeceğiniz ürünleri sevdiklerinize ısmarlayabilmeniz için sizi bekliyor."
                        },
                        section_two:{
                            title:"Nasıl Çalışır?",
                            description:"Uygulamanın nasıl çalıştığını 5 adımda görebilirsiniz.",
                            one:"Anlaşmalı mağazalarımızdan dilediğiniz ürünü seçin.",
                            two:"Ismarlayacağınız kişinin telefon numarasını girin veya rehberinizden seçin.",
                            three:"Ödemenizi yaptıktan sonra ısmarladığınız kişiye sms yolu ile bir karekod gideceketir.",
                            four:"Karekodu paylaşmak için whatsapp, copylink ve diğer sosyal medya ağlarını da kullanabilirsiniz.",
                            five:"Ismarladığınız kişi bu karekodu mağaza görevlisine okutup hediyesini alabilir."
                        },
                        /* section_three:{
                            title:"Kampanyalar"
                        }, */
                        section_sss:{
                            title:"Sıkça Sorulan Sorular",
                            one:{
                                title:"Nasıl Üye Olurum?",
                                description:"Uygulama giriş ekranındaki ‘’kayıt ol’’ butonuna tıklayarak üyelik formunu doldurup uygulamamıza üye olabilirsiniz."
                            },
                            two:{
                                title:"Bugün Bendensin ‘de ödeme seçenekleri nelerdir?",
                                description:"Online kredi kartı ile kredi kartı numaranızı ve CVV bilgisini girerek ödeme yapabilirsiniz"
                            },
                            three:{
                                title:"Ismarladığım kişiye notum var. Nasıl ekleyebilirim?",
                                description:"Ismarlamak istediğiniz ürünleri seçip sepetinize ekledikten sonra, sepette sipariş detaylarının hemen altında bulunan ‘’not eklemek ister misiniz?’’ kutucuğuna iletmek istediğiniz notunuzu yazabilirsiniz."
                            },
                            four:{
                                title:"Ismarlanan ürün kaç gün içerisinde kullanılabilir?",
                                description:"Ismarladığınız kişi tarafından 15 gün içinde kullanılabilir."
                            },
                            see_all_btn:"Tümünü Gör"
                        }
                    },
                    about:{
                        title:"Hakkımızda",
                        subtitle:"Bugün bendensin, anlaşmalı mağazalarımızdaki yüzlerce ürün arasından, seçtiğiniz bir ürünü, ailenize, arkadaşınıza, değer verdiğiniz herkese ısmarlayabileceğiniz bir mobil uygulamadır.",
                        description:"Hediyeleşmek ve sevdiklerimize bir şeyler ısmarlamak kültürümüzün en belirgin ve yaygın özelliklerinden biridir. Dolayısıyla bu uygulama için, Türk insanının ısmarlama geleneğinin dijital ortamda zamandan tasarruf ettiren bir hali diyebiliriz.",
                        band:{
                            one:"Kayıtlı",
                            two:"150,000",
                            three:"Kişi Bugün Bendensin Kullanıyor."
                        },
                        section:{
                            title:"Tristique condimentum urna volutpat eget eget sit.",
                            description:"Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.",
                            one:{
                                title:"Arcu velit etiam in.",
                                description:"Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo"
                            },
                            two:{
                                title:"Mattis tristique.",
                                description:"Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit"
                            }
                        }
                    },
                    /* campaigns:{
                        title:"Kampanyalar",
                        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci nulla interdum consectetur diam tincidunt elementum tristique.",
                    }, */
                    faq:{
                        title:"Sıkça Sorulan Sorular",
                        description:"Bugün Bendensin hakkındaki sık sorulan sorulara buradan ulaşabilirsiniz.",
                        one:{
                            title:"Bugün Bendensin nedir?",
                            description:"Bugün bendensin, anlaşmalı mağazalarımızdaki yüzlerce ürün arasından, seçtiğiniz bir ürünü, ailenize, arkadaşınıza, değer verdiğiniz herkese ısmarlayabileceğiniz bir mobil uygulamadır."
                        },
                        two:{
                            title:"Bugün Bendensin nasıl çalışır?",
                            description:"•\tAnlaşmalı mağazalarımızdan dilediğiniz ürünü seçin <br></br>\n" +
                                "                      •\tIsmarlayacağınız kişinin telefon numarasını girin veya rehberinizden seçin. <br></br>\n" +
                                "                      •\tÖdemenizi yaptıktan sonra ısmarladığınız kişiye sms yolu ile bir karekod gideceketir. <br></br>\n" +
                                "                      •\tKarekodu paylaşmak için vhatsapp, copylink ve diğer sosyal medya ağlarını da kullanabilirsiniz.<br></br>\n" +
                                "                      •\tIsmarladığınız kişi bu karekodu mağaza görevlisine okutup hediyesini alabailir.<br></br>"
                        },
                        three:{
                            title:"Nasıl üye olurum?",
                            description:"Uygulama giriş ekranındaki ‘’kayıt ol’’ butonuna tıklayarak\n" +
                                "                      üyelik formunu doldurup uygulamamıza üye olabilirsiniz."
                        },
                        four:{
                            title:"Bugün Bendensin‘de ödeme seçenekleri nelerdir?",
                            description:"Onine kredi kartı ile kredi kartı numaranızı ve CVV bilgisini girerek ödeme yapabilirsiniz."
                        },
                        five:{
                            title:"Ismarladığım kişiye notum var. Nasıl ekleyebilirim?",
                            description:"Ismarlamak istediğiniz ürünleri seçip sepetinize ekledikten sonra, \n" +
                                "                      sepette sipariş detaylarının hemen altında bulunan ‘’not eklemek ister misiniz?’’ \n" +
                                "                      kutucuğuna iletmek istediğiniz notunuzu yazabilirsiniz."
                        },
                        six:{
                            title:"Ismarlanan ürün kaç gün içerisinde kullanılabilir?",
                            description:"Ismarladığınız kişi tarafından 15 gün içinde kullanılabilir."
                        },
                        seven:{
                            title:"Ismarladığım ürün nasıl teslim edilecek?",
                            description:"Cep telefonunuza gelen karekod ile ilgili mağazaya ulaştıktan sonra, \n" +
                                "                      mağaza görevlisine karekodu okutup hediyenizi alabilirsiniz."
                        },
                        eight:{
                            title:"Siparişlerimi nereden görebilirim?",
                            description:"Siparişlerinizi ‘’Siparişlerim’’ sayfasından takip edebilirsiniz."
                        },
                        nine:{
                            title:"Siparişimin kullanılıp kullanılmadığını görebilir miyim?",
                            description:"‘’ Siparişlerim’’ sayfasından ürününüzün kullanılıp kullanılmadığını takip edebilirsiniz."
                        },
                        ten:{
                            title:"15 gün içinde ürünü kullanmak için vaktim olmadı. Süreyi uzatabilir miyim?",
                            description:"Ücret dahilinde alabileceğiniz hediye kuponu ile ürünü edinme sürenizi uzatabilirsiniz."
                        },
                        eleven:{
                            title:"Hediye gönderdiğim numarayı yanlış yazdım, ne yapmalıyım?",
                            description:"Bizimle iletişime geçerek düzeltilmesini talep edebilir ya da iptal et butonunu kullanarak siparişinizi iptal edip\n" +
                                "                      tekrar doğru numaraya ısmarlama yapabilirsiniz."
                        },
                        twelve:{
                            title:"Yurt dışında da uygulama kullanılabiliyor mu?",
                            description:"Türkiye içi mağazalardan sevdiklerinize yurt dışından ısmarlama yapabilirsiniz."
                        },
                    },
                    contact:{
                        title:"İletişim",
                        description:"Tüm soru ve önerileriniz için bizimle iletişime geçin.",
                        form:{
                            one:"Ad Soyad",
                            two:"E-Posta",
                            three:"Konu",
                            four:"Bizimle ne için iletişime geçiyorsunuz?",
                            btn:"Gönder"
                        },
                        band:{
                            one:"Arayın",
                            two:"Email Gönderin",
                            three:"Ziyaret Edin"
                        }
                    },
                    header:{
                        one:"Anasayfa",
/*                         two:"Kampanyalar",
 */                        three:"Hakkımızda",
                        four:"SSS",
                        five:"İletişim",
                        six:"Sözleşme",
                    },
                    footer: {
                        column_one:{
                            title:"Hakkımızda",
                            one:"Biz Kimiz"
                        },
                        column_two:{
                            title:"Yasal",
                            one:"Gizlilik Sözleşmesi",
                            //two:"Şartlar & Koşullar",
                            three:"Çerez Politikası"
                        },
                        column_three:{
                            title:"İletişim",
                            one:"S.S.S",
                            two:"Destek Masası",
                            three:"İletişim"
                        },
                        column_four:{
                            title:"İndirme"
                        }
                    }
                }
            },
        }
    });

export default i18n;
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import "swiper/css";
import { useTranslation } from 'react-i18next';
SwiperCore.use([Navigation]);

export default function Horizontal() {
  const { t } = useTranslation();
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  return (
    <div>
      <Swiper
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        spaceBetween={60}
        slidesPerView={1}
        className="!pt-16 !pl-16"
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 100,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 100,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 100,
          },
          1366: {
            slidesPerView: 3,
            spaceBetween: 100,
          },
          1920: {
            slidesPerView: 4,
            spaceBetween: 60,
          },
        }}
      >
        <SwiperSlide>
          <Card
            index={1}
            src="/Union.svg"
            p={t('home.section_two.one')}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Card
            index={2}
            src="/Icon_navigation.svg"
            p={t('home.section_two.two')}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Card
            index={3}
            src="/star.svg"
            p={t('home.section_two.three')}
          />
        </SwiperSlide>
        {/* <SwiperSlide>
          <Card
            index={4}
            src="/heart.svg"
            p={t('home.section_two.four')}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Card
            index={5}
            src="/Union.svg"
            p={t('home.section_two.five')}
          />
        </SwiperSlide> */}
        {/* <div className="flex mt-5 justify-end">
          <button ref={navigationPrevRef}>
            <ChevronLeftIcon className="h-12 w-12 bg-white text-fuchsia-500 rounded-md mr-2" />
          </button>
          <button ref={navigationNextRef}>
            <ChevronRightIcon className="h-12 w-12 bg-white text-fuchsia-500  rounded-md" />
          </button>
        </div> */}
      </Swiper>
    </div>
  );
}

const Card = ({ index, src, p }) => (
  <div className="card relative p-8 bg-zinc-50/80 rounded-3xl shadow-md text-center">
    <div className="absolute font-semibold text-white text-7xl flex justify-center items-center bg-sphere w-[99px] h-[99px] -left-12 -top-12">
      {index}
    </div>

    <div className="flex justify-center">
      <img className="homeSliderCardImage" src={src} alt="1" />
    </div>
    <p className="mt-10 font-semibold">{p}</p>
  </div>
);

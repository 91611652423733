import { useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import axios from "axios";
import {useTranslation} from "react-i18next";
export default function Contact() {
  const { t } = useTranslation();
  const [formShow, setformShow] = useState(true);
  const [alertMsg, setAlertMsg] = useState("");

  const fullname = useRef(null);
  const email = useRef(null);
  const subject = useRef(null);
  const description = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("http://185.32.14.112/send-email", {
        full_name: fullname.current.value,
        to: email.current.value,
        subject: subject.current.value,
        description: description.current.value,
      })
      .then(({ data }) => {
        if (data.success) {
          setAlertMsg(data.msg);
        } else {
          setAlertMsg(data.msg);
        }
        setformShow(false);
      })
      .catch((err) => {
        setAlertMsg("Bir hata oluştu lütfen tekrar deneyiniz!");
      });
  };
  return (
    <>
      <Header />
      <div className="relative bg-primary-linear-0 overflow-hidden">
        <div className="contact-sh-1 ">
          <div className="max-w-7xl mx-auto pb-28 mt-10 px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-18 lg:px-8 xl:mt-20">
            <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center">
              <div className="">
                <div className="sm:text-center lg:text-left">
                  <h1 className="text-white md:text-dark-main text-6xl font-semibold mb-5">
                    {t('contact.title')}
                  </h1>
                  <p className="text-white md:text-dark-main font-light text-lg mb-10 md:mb-0">
                    {t('contact.description')}
                  </p>
                </div>
              </div>
              <form
                onSubmit={handleSubmit}
                className="homeSlider2bg max-w-[550px] w-full mx-auto p-8 rounded-xl"
              >
                {formShow ? (
                  <div>
                    <div className="flex flex-col w-full mb-5">
                      <label className="text-gray-700 font-semibold text-sm mb-2">
                        {t('contact.form.one')}
                      </label>
                      <input
                        ref={fullname}
                        className="rounded-md font-light px-4 py-3"
                        placeholder="Ali Kaya"
                        required
                        name="full_name"
                      />
                    </div>
                    <div className="flex flex-col w-full mb-5">
                      <label className="text-gray-700 font-semibold text-sm mb-2">
                        {t('contact.form.two')}
                      </label>
                      <input
                        ref={email}
                        className="rounded-md font-light px-4 py-3"
                        type="email"
                        required
                        name="to"
                        placeholder="alikaya@email.com"
                      />
                    </div>
                    <div className="flex flex-col w-full mb-5">
                      <label className="text-gray-700 font-semibold text-sm mb-2">
                        {t('contact.form.three')}
                      </label>
                      <input
                        ref={subject}
                        name="subject"
                        required
                        type="text"
                        className="rounded-md font-light px-4 py-3"
                        placeholder="Yükseltme Planlanamadı"// placeholder="Unable to Plan Upgrade"
                      />
                    </div>
                    <div className="flex flex-col w-full mb-5">
                      <label className="text-gray-700 font-semibold text-sm mb-2">
                        {t('contact.form.four')}
                      </label>
                      <textarea
                        ref={description}
                        className="rounded-md font-light px-4 py-3"
                        required
                        placeholder="Açıklama"
                        name="description"
                      ></textarea>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="text-primary-main w-full bg-white rounded-lg py-4"
                      >
                        {t('contact.form.btn')}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>{alertMsg}</div>
                )}
              </form>
            </div>
          </div>
          <div className="homeSlider2bg py-16">
            <div className="max-w-7xl grid grid-cols-1 md:grid-cols-3 mx-auto">
              <div className="mx-auto text-center mb-16 md:mb-0">
                <img className="inline" src="/contact1.png" alt="contact1" />
                <h3 className="font-semibold text-3xl text-dark-main mt-3">
                  {t('contact.band.one')}
                </h3>
                <p className="mt-3 font-light text-lg">+90 212 291 51 00</p>
              </div>
              <div className="mx-auto text-center mb-16 md:mb-0">
                <img className="inline" src="/contact2.png" alt="contact2" />
                <h3 className="font-semibold text-3xl text-dark-main mt-3">
                  {t('contact.band.two')}
                </h3>
                <p className="mt-3 font-light text-lg">
                info@bugunbendensin.com
                  {/* <br />
                  help@creatt.co */}
                </p>
              </div>
              <div className="mx-auto text-center md:mb-0">
                <img className="inline" src="/contact1.png" alt="contact1" />
                <h3 className="font-semibold text-3xl text-dark-main mt-3">
                  {t('contact.band.three')}
                </h3>
                <p className="mt-3 font-light text-lg">
                Cumhuriyet Caddesi No:187 D:3 Harbiye
                  <br />
                  Şişli/ İstanbul
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

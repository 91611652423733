function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const googlePlayLink = "https://play.google.com/store/apps/details?id=com.bugunbendensin.customer";
const appStoreLink = "https://apps.apple.com/us/app/bug%C3%BCn-bendensin/id6458876966";

const PlayStore = ({ color }) => (
  <svg
    width="28"
    height="31"
    viewBox="0 0 28 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.043 14.0195L21.5586 10.5039L5.09375 1.01172L18.043 14.0195ZM1.75391 0.25C0.992188 0.660156 0.464844 1.42188 0.464844 2.35938V28.1992C0.464844 29.1367 0.992188 29.8984 1.75391 30.25L16.7539 15.25L1.75391 0.25ZM26.6562 13.4922L23.1992 11.5L19.332 15.25L23.1992 19.0586L26.7148 17.0664C27.7695 16.2461 27.7695 14.3125 26.6562 13.4922ZM5.09375 29.4883L21.5586 20.0547L18.043 16.5391L5.09375 29.4883Z"
      fill={color}
    />
  </svg>
);

const AppStore = ({ color }) => (
  <svg
    width="28"
    height="32"
    viewBox="0 0 28 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    
  >
    <path
      d="M22.8594 16.9141C22.8594 14.3125 24.0547 12.4141 26.375 10.9375C25.0391 9.03906 23.0703 8.05469 20.4688 7.84375C17.9375 7.63281 15.1953 9.25 14.2109 9.25C13.1562 9.25 10.7656 7.91406 8.86719 7.91406C4.92969 7.98438 0.78125 11.0078 0.78125 17.2656C0.78125 19.0938 1.0625 20.9922 1.76562 22.9609C2.67969 25.5625 5.91406 31.8906 9.28906 31.75C11.0469 31.75 12.3125 30.4844 14.6328 30.4844C16.8828 30.4844 18.0078 31.75 19.9766 31.75C23.4219 31.75 26.375 25.9844 27.2188 23.3828C22.6484 21.2031 22.8594 17.0547 22.8594 16.9141ZM18.9219 5.38281C20.8203 3.13281 20.6094 1.02344 20.6094 0.25C18.9219 0.390625 16.9531 1.44531 15.8281 2.71094C14.5625 4.11719 13.8594 5.875 14 7.77344C15.8281 7.91406 17.5156 7 18.9219 5.38281Z"
      fill={color}
    />
  </svg>
);

export default function AppButton({ icon = "google", theme = 1 }) {
  return (
    <a
      href={icon === "google" ? googlePlayLink : appStoreLink}
      className={classNames(
        theme === 1
          ? "bg-primary-main text-white"
          : theme === 2
          ? "bg-white text-primary-main"
          : "bg-linear-variant-5 text-white",
        "w-full shadow-ds-3 flex items-center justify-center px-8 py-3 rounded-xl md:py-4 md:text-lg md:px-10"
      )}
    >
      <div className="px-2">
        {icon === "google" ? (
          <PlayStore color={theme === 2 ? "#D60056" : "white"} />
        ) : (
          <AppStore color={theme === 2 ? "#D60056" : "white"} />
        )}
      </div>
      <div>
        <div className="text-sm font-light">ISMARLA</div>
        {icon === "google" ? "Android" : "iOS"}
      </div>
      
    </a>

  
    
  );
}

import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {useTranslation} from "react-i18next";
export default function Faq() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="relative bg-primary-linear-0 overflow-hidden">
        <div className="sss-sh-1">
          <div className="max-w-5xl mx-auto pb-28 ">
            <div className="grid grid-cols-3">
              <div className="col-span-2 mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-left">
                  <h1 className="text-white text-4xl font-semibold">
                    {t('faq.title')}
                  </h1>
                  <p className="mt-3 text-xl text-white">
                    {t('faq.description')}
                  </p>
                </div>
              </div>
              <div className="col-span-1">
                <img
                  className="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full"
                  src="/Saly-24.png"
                  alt=""
                />
              </div>
            </div>
            <div className="w-full px-6">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.one.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.one.description')}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
                      <br></br>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.two.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.two.description')}

                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <br></br>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.three.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.three.description')}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <br></br>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.four.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.four.description')}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <br></br>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.five.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.five.description')}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <br></br>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.six.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.six.description')}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <br></br>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.seven.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.seven.description')}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <br></br>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.eight.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.eight.description')}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <br></br>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.nine.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.nine.description')}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <br></br>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.ten.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.ten.description')}


                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              
              <br></br>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.eleven.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.eleven.description')}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <br></br>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        open ? "" : "rounded-b-2xl"
                      } mt-8 sm:mt-0 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                    >
                      <span>{t('faq.twelve.title')}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className={`${
                        open ? "" : "rounded-t-2xl"
                      } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                    >
                      {t('faq.twelve.description')}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              {/* {new Array(10).fill(null).map(() => {
                return (
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`${
                            open ? "" : "rounded-b-2xl"
                          } mt-8 flex w-full justify-between rounded-t-2xl bg-purple-100 p-6 text-left text-lg font-semibold text-dark-main`}
                        >
                          <span>Bugün Bendensin nasıl çalışır?</span>
                          <ChevronUpIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-purple-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel
                          className={`${
                            open ? "" : "rounded-t-2xl"
                          } px-6 pb-6 text-lg text-dark-main rounded-b-2xl bg-purple-100`}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Purus ac, ipsum eget nulla cursus sagittis. Amet
                          accumsan, neque tellus gravida suspendisse. Etiam urna
                          massa fermentum pellentesque dui dolor gravida.
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                );
              })} */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

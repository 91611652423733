import Footer from "../components/Footer";
import Header from "../components/Header";
import { useTranslation } from 'react-i18next';
export default function Sozlesme() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="relative bg-primary-main overflow-hidden">
        <div className="about-sh-1">
          <div className="max-w-7xl mx-auto pb-28 mt-10 px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-18 lg:px-8 xl:mt-20">
            <div className="grid grid-cols-1 lg:grid-cols-3">
              <div className="col-span-1">
                <div className="sm:text-center lg:text-left">
                  <h1 className="text-white text-5xl font-semibold">
                    {t('sozlesme.title')}
                  </h1>
                </div>
              </div>
              <div className="col-span-2">
               
                <p className="mt-5 text-lg font-light text-white">
                HABBTECH TEKNOLOJİ SAN. TİC. A.Ş. ("Bugün Bendensin" veya "Şirket") olarak; kişisel verilerinizin işlenmesinde başta özel hayatınızın gizliliği ve güvenliği olmak üzere mahremiyetinizi korumak, kişisel veri güvenliğini sağlamak, temel hak ve özgürlüklerinizi korumak önceliğimizdir. Bu kapsamda 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun ("KVKK") 10. maddesinden doğan aydınlatma yükümlülüğümüzü yerine getirmek amacıyla kişisel verileriniz hakkında sizleri bilgilendirmek isteriz.<br></br>
                <br></br>

Kişisel veri, belirli ya da belirlenebilir nitelikteki bir kişiye ilişkin her türlü bilgi anlamına gelmektedir. Bugün Bendensin tarafından işlenen kişisel verilerinizin hangi amaçla işleneceğine, kimlere ve hangi amaçla aktarılabileceğine, toplanma yöntemi ve hukuki sebebine ve söz konusu kişisel verilere ilişkin haklarınıza aşağıda yer vermekteyiz.
 <br></br>

   A- ÜYELERİMİZ
 <br></br>

    1-  Hangi kişisel verileriniz işlenmekte?<br></br>

 Bugün Bendensin platformunda yer alıp üyelik koşullarını kabul ederek web sitesinden veya mobil cihazlara kurulan uygulamadan giriş yapan ve platformda yer alan ürünleri takip eden ve/veya satın alan kişiler "Üye" olarak tanımlanmaktadır. Bugün Bendensin platformuna üye olmanız halinde aşağıdaki kişisel verileriniz işlenebilmektedir.<br></br>

 • Kimlik Bilgileriniz: Adınız, soyadınız<br></br>

• İletişim Bilgileriniz: Cep telefonu numaranız, e-posta adresiniz,<br></br>

• Müşteri İşlem Bilgileriniz: Sipariş ve fatura bilgileriniz, alışverişlerinizle ilgili gerçekleşen işlem geçmişi bilgisi, talep ve şikayet bilgileriniz, platformda yer alan ürünlere ilişkin yorum yapmanız ve/veya soru sormanız halinde yorum ve sorularınız içerisinde bizzat paylaştığınız bilgiler, Bugün Bendensin web sitesinde chat kanalıyla yapılan konuşma içeriklerine yönelik bilgiler,
<br></br>
• İşlem Güvenliği Bilgileriniz: IP adresi bilgisi, şifre ve parola bilgileri, çerez bilgileri,
<br></br>
• Hukuki İşlem Bilgileriniz: yetkili kişi, kurum ve kuruluşlarla yazışmalardaki bilgiler, dava ve icra dosyalarındaki bilgiler, yasal bilgi talebi bilgileriniz,
<br></br>
• İşitsel Kayıt Bilgileriniz: çağrı merkeziyle görüşmeniz halinde ses kaydınız.<br></br>

 
<br></br>
 <br></br>
Kimlik, iletişim ve müşteri işlem bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir:
<br></br>
    2-  Kişisel verileriniz hangi amaçla işlenmekte ve hangi yöntemle toplanmakta?
<br></br>
• Finans ve muhasebe süreçlerinin yürütülmesi ve denetimi,
<br></br>
• Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
<br></br>
• Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
<br></br>
• Faturalandırma süreçlerinin yürütülmesi,
<br></br>
• İnternet sitesi/mobil uygulamalar üzerinden satın alım işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit edilmesi,
<br></br>
• Açık rızanızın bulunması halinde, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmesi, tercihlerinize uygun ürünlerin tarafınıza sunulması başta olmak üzere sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin artırılmasına yönelik faaliyetlerin yürütülmesi,
<br></br>
• Müşteri memnuniyetini artırmak, platformdan alışveriş yapan müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde kullanabilmek, şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
<br></br>
• Stratejik analiz çalışmalarının yürütülmesi,
<br></br>
• İletişim faaliyetlerinin yürütülmesi,
<br></br>
• İlgi alanlarınızı dikkate alarak ilgilenebileceğiniz ürünlerin sunulması,
<br></br>
• Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun'un ilgili maddeleri kapsamında platformumuz üzerinden akdedilen sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili iletişime geçilmesi ve gerekli bilgilendirmelerin yapılması,
<br></br>
• Açık rızanızın bulunması halinde, Bugün Bendensin nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi,
<br></br>
• İnternet sitesi ve/veya mobil uygulamalardan alışveriş yapan müşterilerimizin tanınması, müşteri çevresi analizinde kullanılmasına yönelik faaliyetlerin yürütülmesi ve bu kapsamda anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket çalışmaları yapılması,
<br></br>
• Ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi,
<br></br>
• Ürünlere yönelik bilgi almanızın sağlanması,
<br></br>
• Mal ve hizmet satış sonrası destek hizmetlerinin yürütülmesi,
<br></br>
• Bilgi güvenliği süreçlerinin yürütülmesi,
<br></br>
• Bugün Bendensin platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,
<br></br>
• Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
<br></br>
• Hukuk işlerinin takibi ve yürütülmesi,
<br></br>
• Yetkili kişi, kamu kurum ve kuruluşlarına bilgi verilmesi.

İşlem güvenliği bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir:
• Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
• Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
• İnternet sitesi/mobil uygulamalar üzerinden satın alım işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit edilmesi,
• Açık rızanızın bulunması halinde, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmesi, tercihlerinize uygun ürünlerin tarafınıza sunulması başta olmak üzere sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi,
• Müşteri memnuniyetini artırmak, platformdan alışveriş yapan müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde kullanabilmek, Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
• Bilgi güvenliği süreçlerinin yürütülmesi,
• Bugün Bendensin platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,
• Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
• Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
 
Hukuki işlem bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, mobil uygulama, internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir:
• Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
• Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
• Ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi,
• Bilgi güvenliği süreçlerinin yürütülmesi,
• Bugün Bendensin platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,
• Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
• Hukuk işlerinin takibi ve yürütülmesi,
• Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
<br></br>
 
<br></br>
İşitsel kayıt bilgileriniz; çağrı merkeziyle iletişime geçmeniz halinde aşağıdaki amaçlarla toplanmakta ve işlenmektedir:
<br></br>
• Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
<br></br>
• İletişim faaliyetlerinin yürütülmesi,
<br></br>
• Kampanyaların yürütülmesi, tercihlerinize uygun ürünlerin tarafınıza sunulması başta olmak üzere sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi,
<br></br>
• Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
<br></br>
• İlgi alanlarınızı dikkate alarak ilgilenebileceğiniz ürünlerin sunulması,
<br></br>
• Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun'un ilgili maddeleri kapsamında platformumuz üzerinden akdedilen sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili iletişime geçilmesi ve gerekli bilgilendirmelerin yapılması,
<br></br>
• Açık rızanızın bulunması halinde, Bugün Bendensin nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi,
<br></br>
• İnternet sitesi ve/veya mobil uygulamalardan alışveriş yapan müşterilerimizin tanınması, müşteri çevresi analizinde kullanılmasına yönelik faaliyetlerin yürütülmesi ve bu kapsamda anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket çalışmaları yapılması,
<br></br>
• Ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi,
<br></br>
• Bugün Bendensin platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,
<br></br>
• Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
<br></br>
• Hukuk işlerinin takibi ve yürütülmesi,
<br></br>
• Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
<br></br>

<br></br>
3- Kişisel verileriniz kimlere hangi amaçlarla aktarılabilmekte?
<br></br>
 Şirketimiz, kişisel verilerinizi gerekli teknik ve idari güvenlik tedbirlerini alarak işlemeye özen göstermektedir. İş faaliyetlerinin yürütülmesi veya denetimi, iş sürekliliğinin sağlanması, dijital altyapıların işletilmesi farklı paydaşlarla sürekli veri akışını zaruri kıldığı için işlediğimiz kişisel verileri bu gibi belirli amaçlarla üçüncü kişilere aktarmak durumundayız. Ayrıca, sözleşmesel ve kanuni yükümlülüklerimizi tam ve gereği gibi yerine getirebilmemiz için kişisel verilerinizin doğru ve güncel olması çok önemlidir. Bunun için de muhtelif iş ortaklarıyla ve hizmet sağlayıcılarla çalışmak durumundayız. Bununla birlikte, kişisel verileri alan üçüncü kişinin veri koruma politikalarından dolayı ve üçüncü kişinin sorumluluğundaki risk alanında meydana gelen ihlallerden şirketimiz sorumlu değildir.
<br></br>
Kişisel verileriniz, pazaryeri operasyonun yürütülmesi, sipariş verdiğiniz ürünlerin teslimat süreçlerinin yürütülmesi, süreçlerinize yönelik yardım ve destek taleplerinizin yönetilmesi, üyelerimizin deneyimini ve memnuniyetin arttırmaya yönelik faaliyetlerin yürütülmesi, şirketimiz nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi, şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi, sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi, iş sürekliliğinin sağlanması, bilgi güvenliğinin sağlanması, kanuni yükümlülüklerin yerine getirilmesi amaçları başta olmak üzere ve işbu aydınlatma metninin ikinci maddesinde belirtilen amaçlarla sınırlı olarak gerekli olduğu ölçüde:
<br></br>

<br></br>
Şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş olması ve hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması, sözleşmenin kurulması veya ifası için gerekli olması hukuki sebeplerine dayanarak;
<br></br>
• Satın alım süreçlerinin yürütülmesi amacıyla ürünün satıcısı ve hizmet sağlayıcıyla,
<br></br>
• Alternatif teslimat modeliyle ürünlerin teslimatının yapılabilmesi amacıyla anlaşmalı olunan iş ortaklarımızla ve hizmet sağlayıcılarla,
<br></br>
• Faturalandırma süreçlerinin yürütülmesi amacıyla iş ortaklarımız, satın alınan ürünün satıcısı, danışmanlarımız ve hizmet sağlayıcılarımızla, bankalarla, mali müşavirlerimizle,
<br></br>
• Mal ve hizmet satış ve satış sonrası destek hizmetlerinin sunulması için çağrı merkezi hizmeti sunan iş ortakları ve diğer hizmet sağlayıcılarıyla,
<br></br>
• Hizmetlerin kalite kontrol, şikâyet yönetimi ve risk analizi alanında hizmet sunan iş ortakları ve hizmet sağlayıcılarıyla,
<br></br>
• Finansal ve muhasebe süreçlerinin yönetimi, risklerin tespiti, değerlendirilmesi, dolandırıcılıkların önlenmesi için ilgili iş ortaklarımız, danışmanlarımız ve hizmet sağlayıcılarımızla, bankalarla, mali müşavirlerimizle,
<br></br>
• Müşteriye e-faturasının elektronik olarak gönderilmesi için e-fatura iş ortağıyla,
<br></br>
• Vergisel yükümlülüklerin yerine getirilmesi için vergi daireleriyle, vergi denetimleri sırasında fatura ve tahsilat makbuzlarının T.C. Hazine ve Maliye Bakanlığı yetkilileriyle,
<br></br>
• İletilerin yönetilmesi için ileti yönetim sistemi (İYS) kayıt bağlamında Ticaret Bakanlığı ve yetkilendirdiği şirketle ve ileti gönderimi için yetkili ticari ileti altyapı sağlayıcıyla,
<br></br>
• Bilişim altyapımızı sağlayan, işleten veya hizmet sunan iş ortaklarımızla ve hizmet sağlayıcılarımızla,
<br></br>
• Risk yönetimi ve finansal raporlama süreçlerinin yürütülmesi alanında hizmet sunan iş ortaklarımızla,
<br></br>

<br></br>

<br></br>
Açık rızanızın bulunması hukuki sebebine dayanarak;
<br></br>
• Web sitesi kullanım tercihleri ve gezinti geçmişi, segmentasyon yapılması ve müşteri ile beğeni ve tercihleri doğrultusunda iletişime geçilmesi amacıyla ilgili hizmet sağlayıcı ve iş ortağıyla,
<br></br>
• Profilleme, analiz, sunulan hizmetlerin özelleştirilmesi, pazarlama ve reklam amaçlarıyla bu alanda hizmet sunan iş ortaklarıyla ve hizmet sağlayıcılarla,
<br></br>
• Pazarlama süreçlerinin yönetilmesi amacıyla ilgili iş ortaklarımızla,
<br></br>
 
<br></br>
Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması, şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş olması ve hukuki yükümlülüğünün yerine getirebilmesi için zorunlu olması hukuki sebeplerine dayanarak;
<br></br>
• Hukuki yükümlülüklerin yerine getirilmesi kapsamında avukatlar, denetçiler, adli bilişim uzmanları, siber güvenlik danışmanları, vergi danışmanları ile danışmanlık ve hizmet aldığımız diğer üçüncü kişilerle ve iş ortaklarıyla,
<br></br>
• Düzenleyici ve denetleyici kurumlar ile mahkeme ve icra müdürlükleri gibi yetkili kamu kurum ve kuruluşlarıyla,
<br></br>
• Kişisel verilerinizi talep etmeye yetkili olan diğer kamu kurum veya kuruluşlarıyla, yurtiçi ve/veya yurtdışında bulunan iştiraklerimizle, tedarikçilerimizle, iş ortaklarımızla, anlaşmalı olduğumuz bankalarla ve ürün veya hizmet aldığımız üçüncü kişilerle paylaşılabilmektedir.
<br></br>

<br></br>

 <br></br>
4-Kişisel verilerinizin toplanmasının hukuki sebebi nedir?
 <br></br>
Bugün Bendensin tarafından "Hangi kişisel verileriniz işlenmekte?" başlığı altında yer alan kişisel verileriniz, yukarıda yer alan amaçlarla ve KVKK’nın 5., 8. Ve 9. maddelerinde belirtilen ve aşağıda yer verilen hukuka uygunluk sebeplerine dayanılarak işlenmektedir:
 <br></br>
• 6563 Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, 6102 Sayılı Türk Ticaret Kanunu, 5237 Sayılı Türk Ceza Kanunu ve 6502 sayılı Tüketicinin Korunması Hakkında Kanun başta olmak üzere Şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş olması hukuki sebebine dayanarak; şirket platformunda yer alan operasyonların güvenliğinin sağlanması, bilgi güvenliği süreçlerinin yürütülmesi, faaliyetlerin mevzuata uygun yürütülmesinin sağlanmasına yönelik faaliyetler başta olmak üzere mevzuattan kaynaklanan yükümlülüklerimizin yerine getirilmesi,
<br></br>
• Açık rızanızın bulunması hukuki sebebine dayanarak; platformda yer alan ürünlere yönelik sorularınızın ve/veya yorumlarınızın ad-soyad bilginiz ile birlikte yayınlanması, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmesi, tercihlerinize uygun ürünlerin tarafınıza sunulması başta olmak üzere sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi, Bugün Bendensin nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi, ticari elektronik iletiler gönderilmesi ve yurtdışına kişisel veri aktarımı yapılması.
<br></br>
• Sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, kişisel verilerinizin işlenmesinin gerekli olması hukuki sebebine dayanarak; üyelik sözleşmesinin kurulması, mesafeli satış sözleşmesi gibi Tüketicinin Korunması Hakkında Kanun'un ilgili maddeleri kapsamında platformumuz üzerinden akdedilen sözleşmelerin kurulması faaliyetlerinin yürütülmesi, akdettiğimiz sözleşmelerin ifasına yönelik faaliyetlerin yürütülmesi, satın alım işlemlerinizin gerçekleştirilmesine yönelik faaliyetlerin yürütülmesi, şirketin iş faaliyetlerinin yürütülmesi ve denetimi, teslimat süreçlerinin takibinin yapılması, ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi, finans ve muhasebe süreçlerinin yürütülmesi ve denetimi, iletişim faaliyetlerinin yürütülmesi,
<br></br>
• Şirketimizin hukuki yükümlülüğünün yerine getirebilmesi için zorunlu olması hukuki sebebine dayanarak; Mesafeli Sözleşmeler Yönetmeliği ve Elektronik Ticarette Hizmet Sağlayıcı ve Aracı Hizmet Sağlayıcılar Hakkında Yönetmelik başta olmak üzere Şirketimizin tabii olduğu ikincil mevzuatta yer alan ve/veya yetkili merciiler tarafından yayınlanan karar, kılavuz ve rehberlerde belirtilen hukuki yükümlülüklerinin yerine getirilmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi, faaliyetlerin mevzuata uygun yürütülmesinin sağlanması, hukuk işlerinin takibi ve yürütülmesi, finans ve muhasebe işlerinin yürütülmesi,
<br></br>
• Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması hukuki sebebine dayanarak; hukuk ve dava işlerinin yürütülmesi,
<br></br>
• Temel hak ve özgürlüklerine zarar vermemek kaydıyla şirketimizin meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebine dayanarak; şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi ve sizlere daha iyi bir alışveriş deneyiminin sağlanması,
<br></br>
    
<br></br>
KİŞİSEL VERİLERİNİZ NASIL KORUNMAKTA?
<br></br>
 
<br></br>
Bugün Bendensin ile paylaşılan kişisel veriler, Bugün Bendensin gözetimi ve kontrolü altındadır. Bugün Bendensin, yürürlükteki ilgili mevzuat hükümleri gereğince bilginin gizliliğinin ve bütünlüğünün korunması amacıyla gerekli organizasyonu kurmak ve teknik önlemleri almak ve uyarlamak konusunda veri sorumlusu sıfatıyla sorumluluğu üstlenmiştir. Bu konudaki yükümlülüğümüzün bilincinde olarak;
<br></br>
 • Veri gizliliğini konu alan uluslararası ve ulusal teknik standartlara uygun surette periyodik aralıklarda sızma testleri yaptırılmaktadır.
<br></br>
• Web sitesi, mobil site ve mobil uygulama aracılığıyla Bugün Bendensin’e ilettiğiniz kişisel verileriniz SSL (Secure Sockets Layer) teknolojisi kullanılarak korunmaktadır.
<br></br>
• Kişisel veri işleme faaliyetlerine ilişkin düzenli olarak risk analizleri yapılmakta ve risklerin azaltılması için aksiyonlar alınmaktadır.
<br></br>
• Kişisel verilere yetkisiz erişimleri engellemek için erişim ve yetki kontrolleri uygulanmaktadır.
<br></br>
• Bu kapsamda veri işleme politikalarımızı her zaman güncellediğimizi bilginize sunarız.
 <br></br>

<br></br>
KİŞİSEL VERİLERİNİZİN KORUNMASINA YÖNELİK HAKLARINIZ
 <br></br>

<br></br>
Bu Aydınlatma Metninin "Hak ve Talepleriniz İçin İletişim" bölümünde yer alan yöntemlerle Şirketimize başvurarak,
<br></br>
• Kişisel verilerinizin işlenip işlenmediğini öğrenme,
<br></br>
• İşlenmişse buna ilişkin bilgi talep etme,
<br></br>
• Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
<br></br>
• Yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri bilme,
<br></br>
• Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,
<br></br>
• KVKK’da öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme,
<br></br>
• Yukarıda belirtilen düzeltme, silinme ve yok edilme şeklindeki haklarınız uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
<br></br>
• İşlenen kişisel verilerinizin münhasıran otomatik sistemler ile analiz edilmesi sureti ile aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
<br></br>
• Kişisel verilerinizin ilgili mevzuata aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararınızın giderilmesini talep etme haklarına sahipsiniz.
<br></br>

<br></br>
Kişisel verilerle ilgili mevzuat değişikliklerinden nasıl haberdar olabilirsiniz?
<br></br>
KVKK uyarınca sahip olduğunuz haklar şirketimizin sorumluluk alanındadır. Kişisel verilerinizi bu bilinçle ve mevzuatın gerektirdiği ölçüde işlediğimizi, yasal değişikliklerin olması halinde sayfamızda yer alan bu bilgileri yeni mevzuata uygun güncelleyeceğimizi, yapılan güncellemeleri de bu sayfa üzerinden her zaman kolaylıkla takip edebileceğinizi size bildirmek isteriz.
<br></br>
 
<br></br>
Verinin güncel ve doğru tutulduğundan nasıl emin olabilirsiniz?
<br></br>
KVKK’nın 4. maddesi uyarınca şirketimizin kişisel verilerinizi doğru ve güncel olarak tutma yükümlülüğü bulunmaktadır. Bu kapsamda şirketimizin yürürlükteki mevzuattan doğan yükümlülüklerini yerine getirebilmesi için müşterilerimizin şirketimiz ile doğru ve güncel verilerini paylaşması gerekmektedir. Verilerinizin herhangi bir surette değişikliğe uğraması halinde aşağıda belirtilen iletişim kanallarından bizimle iletişime geçerek verilerinizi güncellemenizi rica ederiz.
 <br></br>


                </p>
              </div>
            </div>
          </div>
          <div className="homeSlider2bg py-16">
            <div className="max-w-7xl mx-auto text-center">
              <h2 className="font-semibold text-4xl text-dark-main">
                
              </h2>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="py-16">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-14 px-4 sm:px-6 lg:px-8">
          <div className="lg:hidden mx-auto">
            <img src="/Saly-31.png" alt="asd" />
          </div>
          <div>
            <h2 className="font-semibold text-4xl text-dark-main">
              {t('about.section.title')}
            </h2>
            <p className="mt-5 text-xl">
              {t('about.section.description')}
            </p>
            <div className="grid grid-cols-2 gap-14 mt-32">
              <div>
                <h3 className="font-semibold text-xl text-dark-main mb-5">
                  {t('about.section.one.title')}
                </h3>
                <p className="font-light text-lg text-dark-main">
                  {t('about.section.one.description')}
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl text-dark-main mb-5">
                  {t('about.section.two.title')}
                </h3>
                <p className="font-light text-lg text-dark-main">
                  {t('about.section.two.description')}
                </p>
              </div>
            </div>
          </div>
          <div className="hidden lg:block mx-auto">
            <img src="/Saly-31.png" alt="asd" />
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

import Home from "./pages/Home";
import Campaigns from "./pages/Campaigns";
import About from "./pages/About";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Sozlesme from "./pages/Sozlesme";
const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/kampanyalar",
    element: <Campaigns />,
  },
  {
    path: "/hakkimizda",
    element: <About />,
  },
  {
    path: "/sss",
    element: <Faq />,
  },
  {
    path: "/iletisim",
    element: <Contact />,
  },
  {
    path: "/privacy",
    element: <Sozlesme />,
  },
];

export default routes;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import "swiper/css";
SwiperCore.use([Navigation]);
export default function HomeSlider2() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  return (
    <>
      <Swiper
        className="mySwiper"
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
      >
        {/* 
        <SwiperSlide className="flex justify-center">
          <img
            className="rounded-3xl"
            src="/homeslider2.jpg"
            alt="Kampanya Resmi"
          />
        </SwiperSlide>
        <SwiperSlide className="flex justify-center">
          <img
            className="rounded-3xl"
            src="/homeslider2.jpg"
            alt="Kampanya Resmi"
          />
        </SwiperSlide> */}
        <div className="absolute flex h-full w-full z-50 top-0 justify-between">
          <button ref={navigationPrevRef}>
            <ChevronLeftIcon className="h-12 w-12 bg-white text-fuchsia-500 rounded-md mr-2" />
          </button>
          <button ref={navigationNextRef}>
            <ChevronRightIcon className="h-12 w-12 bg-white text-fuchsia-500  rounded-md" />
          </button>
        </div>
      </Swiper>
    </>
  );
}

import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import {useTranslation,Trans } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const lngs = {
  tr: { nativeName: 'Türkçe' },
  en: { nativeName: 'English' },
  //ar: { nativeName: 'Arabic' }
};

export default function Header() {
  const { t, i18n } = useTranslation();
  return (
    <Disclosure as="nav" className="bg-white shadow sticky z-10">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                {/* <CustomLink to={"/"}><img
                    className="block lg:hidden h-9 w-auto"
                    src="/logo.png"
                    alt="logo"
                  /></CustomLink> */}
                  <CustomLink to={"/"}><img
                    className="hidden lg:block h-9 w-auto"
                    src="/logo.png"
                    alt="logo"
                  /></CustomLink>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    <CustomLink to={"/"}>
                      {t('header.one')}
                    </CustomLink>
                    {/* <CustomLink to={"/kampanyalar"}>
                      {t('header.two')}
                    </CustomLink> */}
                    <CustomLink to={"/hakkimizda"}>
                      {t('header.three')}
                    </CustomLink>
                    <CustomLink to={"/sss"}>
                      {t('header.four')}
                    </CustomLink>
                    <CustomLink to={"/iletisim"}>
                      {t('header.five')}
                    </CustomLink>
                    <CustomLink to={"/privacy"}>
                      {"Sözleşme"}
                    </CustomLink>
                    <div id="canli-destek" className={"text-black border-0 px-3 py-2 rounded-md text-md cursor-pointer"}>
                      Canlı Destek
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {Object.keys(lngs).map((lng) => (
                    <button key={lng} style={{ marginRight:10,fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                      {lngs[lng].nativeName}
                    </button>
                ))}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 bg-primary-linear-0 md:bg-white">
              <CustomMobileLink to={"/"}>
                Anasayfa
              </CustomMobileLink>
             {/*  <CustomMobileLink to={"/kampanyalar"}>
                Kampanyalar
              </CustomMobileLink> */}
              <CustomMobileLink to={"/hakkimizda"}>
                Hakkımızda
              </CustomMobileLink>
              <CustomMobileLink to={"/sss"}>
                SSS
              </CustomMobileLink>
              <CustomMobileLink to={"/privacy"}>
                Gizlilik Sözleşmesi
              </CustomMobileLink>
              <CustomMobileLink to={"/iletisim"}>
                İletişim
              </CustomMobileLink>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      className={classNames(
        match ? "text-black font-bold border-0" : "text-black hover:font-bold",
        "px-3 py-2 rounded-md text-md"
      )}
      to={to}
      {...props}
    >
      {children}
    </Link>
  );
}

function CustomMobileLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      className={classNames(
        match ? "bg-primary-main " : " hover:bg-primary-main",
        "block px-3 py-2 text-base font-medium text-white rounded-lg"
      )}
      to={to}
      {...props}
    >
      {children}
    </Link>
  );
}

import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Cookie({ visible, onClose }) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Çerez Politikası
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                        KİŞİSEL VERİLERİN KORUNMASI HAKKINDA AÇIKLAMA VE GİZLİLİK POLİTİKASI
HABBTECH TEKNOLOJİ SAN. TİC. A.Ş ’ye (‘’Bugün Bendensin’’) aktarılan kişisel verilerin korunması konusundaki temel bilgilere aşağıda yer verilmiştir. Bugün Bendensin, 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") m. 10'dan doğan aydınlatma yükümlülüğünü yerine getirmek amacıyla aşağıdaki açıklamaları müşterilerimizin ve web-sitemizi ve/veya mobil uygulamalarımızı kullanan 3. kişilerin dikkatine sunar. Bugün Bendensin işbu Kişisel Verilerin Korunması Hakkında Açıklama metnini yürürlükteki mevzuatta yapılabilecek değişiklikler çerçevesinde her zaman güncelleme hakkını saklı tutar.
<br></br>
1) Bugün Bendensin’in kişisel verileri toplamasının yasal dayanağı nedir?
Müşterilerimizin kişisel verilerinin kullanılması konusunda çeşitli kanunlarda düzenlemeler bulunmaktadır. En başta KVKK ile kişisel verilerin korunması esasları belirlenmiştir. Ayrıca 6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun da kişisel verilerin korunmasına ilişkin hüküm içermektedir. 5237 Sayılı Türk Ceza Kanunu hükümleri yoluyla da kişisel verilerin korunması için bazı hallerde cezai yaptırımlar öngörülmüştür.
Diğer yandan, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği'nden doğan yükümlülüklerimizin ifası amacıyla verilerin toplanması ve kullanılması gerekmektedir.
<br></br>
2) Bugün Bendensin kişisel verilerin toplanmasında hangi yöntemleri kullanıyor?
 www.bugunbendensin.com web sitesinden veya mobil uygulamalardan işlem yapan müşterilerimizin verdikleri veriler, müşterilerimizin rızaları ve mevzuat hükümleri uyarınca Bugün Bendensin tarafından işlenmektedir.
Bugün Bendensin’e ait olan www.bugubendensin.com web sitesi çerez (cookie) kullanan bir sitedir. Çerez; kullanılmakta olan cihazın internet tarayıcısına ya da sabit diskine depolanarak söz konusu cihazın tespit edilmesine olanak tanıyan, çoğunlukla harf ve sayılardan oluşan bir dosyadır.
www.bugunbendensin.com ziyaretçilerine daha iyi hizmet verebilmek amacıyla ve yasal yükümlülüğü çerçevesinde, işbu Kişisel Verilerin Korunması Hakkında Açıklama metninde belirlenen amaçlar ve kapsam dışında kullanılmamak kaydı ile gezinme bilgilerinizi toplayacak, işleyecek, üçüncü kişilerle paylaşacak ve güvenli olarak saklayacaktır.
www.bugunbendensin.com çerezleri; günlük dosyaları, boş gif dosyaları ve/veya üçüncü taraf kaynakları yoluyla topladığı bilgileri tercihlerinizle ilgili bir özet oluşturmak amacıyla depolar.  www.bugunbendensin.com size özel tanıtım yapmak, promosyonlar ve pazarlama teklifleri sunmak, web sitesinin veya mobil uygulamanın içeriğini size göre iyileştirmek ve/veya tercihlerinizi belirlemek amacıyla; site üzerinde gezinme bilgilerinizi ve/veya site üzerindeki kullanım geçmişinizi izleyebilmektedir.
www.bugunbendensin.com çevrimiçi ve çevrimdışı olarak toplanan bilgiler gibi farklı yöntemlerle veya farklı zamanlarda site üzerinde sizden toplanan bilgileri eşleştirebilir ve bu bilgileri üçüncü taraflar gibi başka kaynaklardan alınan bilgilerle birlikte kullanabilir.
<br></br>

www.bugunbendensin.com mobil uygulamasında oturum çerezleri ve kalıcı çerezler kullanmaktadır. Oturum kimliği çerezi, tarayıcınızı kapattığınızda sona erer. Kalıcı çerez ise sabit diskinizde uzun bir süre kalır. İnternet tarayıcınızın "yardım" dosyasında verilen talimatları izleyerek veya "www.allaboutcookies.org" veya "www.youronlinechoices.eu" adresini ziyaret ederek kalıcı çerezleri kaldırabilir ve hem oturum çerezlerini hem de kalıcı çerezleri reddedebilirsiniz. Kalıcı çerezleri veya oturum çerezlerini reddederseniz, web sitesini, mobil uygulamayı kullanmaya devam edebilirsiniz fakat web sitesinin, mobil uygulamanın tüm işlevlerine erişemeyebilirsiniz veya erişiminiz sınırlı olabilir.
<br></br>

3) İnternet Sitesi Çerezleri Nasıl Kullanılmaktadır?
Bugün Bendensin’e ait olan www.bugunbendensin.com web sitesi çerez (cookie) kullanan bir sitedir. Çerez; kullanılmakta olan cihazın internet tarayıcısına ya da sabit diskine depolanarak söz konusu cihazın tespit edilmesine olanak tanıyan, çoğunlukla harf ve sayılardan oluşan bir dosyadır.
www.bugunbendensin.com çerezleri; günlük dosyaları, boş gif dosyaları ve/veya üçüncü taraf kaynakları yoluyla topladığı bilgileri tercihlerinizle ilgili bir özet oluşturmak amacıyla depolar.
Oturum çerezleri (session cookies) ve kalıcı çerezler (persistent cookies) olmak üzere sitelerimiz genelinde iki tür çerez kullanmaktayız. Oturum çerezleri geçici çerezler olup sadece tarayıcınızı kapatıncaya kadar geçerlidirler. Kalıcı çerezler siz silinceye veya süreleri doluncaya (bu şekilde çerezlerin cihazında ne kadar kalacağı, çerezlerin "kullanım ömürlerine" bağlı olacaktır) kadar sabit diskinizde kalırlar.
www.bugunbendensin.com çerezleri; yaptığınız tercihleri hatırlamak ve web sitesi/mobil uygulama kullanımınızı kişiselleştirmek için kullanır. Bu kullanım parolanızı kaydeden ve web sitesi/mobil uygulama oturumunuzun sürekli açık kalmasını sağlayan, böylece her ziyaretinizde birden fazla kez parola girme zahmetinden kurtaran çerezleri ve web sitesi/mobil uygulamaya daha sonraki ziyaretlerinizde sizi hatırlayan ve tanıyan çerezleri içerir.
www.bugunbendensin.com web sitesine nereden bağlandığınız, web sitesi/mobil uygulama üzerinde hangi içeriği görüntülediğiniz ve ziyaretinizin süresi gibi web sitesini/mobil uygulamayı nasıl kullandığınızın ölçümlenmesi dahil olmak üzere web sitesini/mobil uygulamayı nasıl kullandığınızı tespit etmek için kullanır.
www.bugunbendensin.com web sitesi çerezleri ayrıca; arama motorlarını, web sitesi, mobil uygulamasını ve/veya web sitesinin reklam verdiği internet sitelerini ziyaret ettiğinizde ilginizi çekebileceğini düşündüğü reklamları size sunabilmek için "reklam teknolojisini" devreye sokmak amacıyla kullanabilir. Reklam teknolojisi, size özel reklamlar sunabilmek için web sitesine/mobil uygulamaya ve web sitesinin reklam verdiği web sitelerine/mobil uygulamalarına yaptığınız önceki ziyaretlerle ilgili bilgileri kullanır. Bu reklamları sunarken, web sitesinin sizi tanıyabilmesi amacıyla tarayıcınıza benzersiz bir üçüncü taraf çerezi yerleştirilebilir. Bugün Bendensin ayrıca Google, Inc. tarafından sağlanan bir web analizi hizmeti olan Google Analytics kullanmaktadır. Google Analytics, çerezleri kullanıcıların web sitesini, mobil uygulamayı ve/veya mobil sitesini nasıl kullandıklarını istatistiki bilgiler/raporlar ile analiz etmek amacıyla kullanır. Google Analytics kullanımı hakkında daha fazla bilgi için (reddetme seçenekleri dahil), şu adresi ziyaret edebilirsiniz: http://www.google.com/intl/tr/policies/privacy/#infocollect 
Mobil uygulamada çerez yerine ilgili uygulamanın SDK''sı (Software Development Kit) kullanılmaktadır.
<br></br>

Aşağıdaki yöntemleri kullanarak çerezlere izin verme ve reddetme imkanını kullanabilirsiniz:
Google Chrome	Tarayıcınızın adres bölümünde yer alan, "kilit işareti"ni tıklayarak, "çerezler" sekmesinden çerezlere izin verebilir veya engelleyebilirsiniz.
Internet Explorer	Tarayıcınızın sağ üst bölümünde yer alan "Araçlar" bölümünden güvenlik sekmesini tıklayarak "izin ver" veya "izin verme" şeklinde çerezleri yönetebilirsiniz.
Mozilla Firefox	Tarayıcınızın sağ üst köşesinde yer alan "menüyü aç" sekmesini tıklayınız. "Seçenekler" görselini tıklayarak "Gizlilik ve Güvenlik" butonunu kullanarak çerezleri yönetebilirsiniz.
Opera			Tarayıcınızın "Tercihler" bölümünde "Gelişmiş"i seçerek "çerezler" bölümünden çerez yönetimini yapabilirsiniz.
Safari	Telefonunuzun "Ayarlar" bölümünden "safari" sekmesini seçip, "Gizlilik ve Güvenlik" Bölümünden tüm çerez yönetiminizi yapabilirsiniz.
<br></br>

Yukarıdaki seçeneklerin yanı sıra; tüm çerezler hakkında bilgi sahibi olmak ve çerez yönetimi için: https://www.allaboutcookies.org , https://www.youronlinechoices.eu/ adresini ziyaret edebilirsiniz, veya "Privacy Badger" uygulamasını kullanabilirsiniz (https://www.eff.org/tr/privacybadger) Kalıcı çerezleri veya oturum çerezlerini reddederseniz, web sitesini, mobil uygulamayı ve mobil sitesini kullanmaya devam edebilirsiniz fakat web sitesinin, mobil uygulamanın ve mobil sitesinin tüm işlevlerine erişemeyebilirsiniz veya erişiminiz sınırlı olabilir.
<br></br>

Bugün Bendensin'de yer alan çerezlere ilişkin bilgiler aşağıdaki tablolarda yer almaktadır:	<br></br>
	
Cookie Service Provider	Cookie İsmi	Cookie Amacı	Cookie Tipi	Cookie Süresi<br></br>

Google	_ga	Analytics cihaz bilgisi - Google Tag Manager sisteminde yer alan uygulamaların kullanması için	Persistent Cookie	2 Yıl<br></br>

Google	_gaexp		AB testleri hakkında bilgileri tutan cookie - Google Tag Manager sisteminde yer alan uygulamarın kullanması için	Persistent Cookie	85 Gün<br></br>

Google		_gid	Kullanıcı tanımlama için		Persistent	1 Gün<br></br>

Yandex	_ym_isad	Reklam engelleme tespiti için	Persistent Cookie	2 Gün<br></br>

Yandex	_ym_uid	Kullanıcıları tanımlama için	Persistent Cookie	1 Yıl<br></br>

BugünBendensin		AbTesting	A/B Test yönetimi için kullanılan değer bu cookiede saklanmaktadır.	Persistent Cookie	2 Yıl<br></br>

BugünBendensin		WebAbTesting	Coklu A/B Test yönetimi için kullanılan değerler bu cookiede saklanmaktadır.	Persistent Cookie	2 Yıl<br></br>

BugünBendensin	COOKIE_CookieLawInformationPermission	Kişisel veri politikalarımıza ait izinler bu cookiede saklanmaktadır.	Non Persistent Cookie		Tarayıcı oturum süresi boyunca
<br></br>
BugünBendensin		COOKIE_Popup	Kullanıcıya gösterilen popuplar ile ilgili teknik veriler bu cookiede saklanmaktadır.	Persistent Cookie	1 Saat
<br></br>
BugünBendensin		COOKIE_TY.Entrance	Login olmuş kullanıcıya ait bilgilerin saklandığı cookiedir.	Persistent Cookie	1 Ay
<br></br>
BugünBendensin	COOKIE_TY.FirstVisit	Kullanıcının ilk ziyaret bilgisinin saklandığı cookiedir.	Persistent Cookie	1 Ay
<br></br>
BugünBendensin		COOKIE_TY.UserAlreadyLogged	Kullanıcının hali hazırda login olup olmadığı bilgisinin saklandığı cookiedir.	Non Persistent Cookie	Tarayıcı oturum süresi boyunca
<br></br>
BugünBendensin	COOKIE_uic	Login olmuş kullanıcıya ait access token olmayan demografik bilgilerinin saklandığı cookiedir.	Persistent Cookie	30 Dakika
<br></br>
BugünBendensin		COOKIE_UserAlreadyLogged	Kullanıcının hali hazırda login olup olmadığı bilgisinin saklandığı cookiedir.	Non Persistent Cookie	Tarayıcı oturum süresi boyunca
<br></br>
BugünBendensin	COOKIE_UserTypeStatus	Kullanıcıların statulerinin saklandığı cookiedir.	Persistent Cookie	12 Saat
<br></br>
BugünBendensin	hvtb	Bir kullanıcının daha önce BugünBendensin'ı ziyaret edip etmediği bilgisini veriyor	Persistent Cookie	2 Yıl
<br></br>
BugünBendensin		pid	Kalıcı olarak kullanıcıya verilen ID nin saklandığı cookiedir.	Persistent Cookie	1 Yıl 354 Gün
<br></br>
BugünBendensin	sid	Kullanıcıya ait session ID değerinin saklandığı cookiedir.	Persistent Cookie	30 Dakika
<br></br>
BugünBendensin	SiteHash	Cookielere ait hash bilgisinin saklandığı cookiedir.	Persistent Cookie	7 Gün
<br></br>
BugünBendensin		VisitorTypeStatus	Ziyaretçilerin statulerinin saklandığı cookiedir.	Persistent Cookie	Tarayıcı oturum süresi boyunca
<br></br>
Qualaroo	ki_r	Kişinin siteye indiği referrer cookiesi, document.referrer'dan gelir.	Persistent Cookie	5 Yıl
<br></br>
Qualaroo	ki_t	Anket zaman damgaları ve görüntüleme sayılarının bulunduğu cookiedir.	Persistent Cookie	5 Yıl
<br></br>
BugünBendensin	NSC_QDJUFTU-IUUQT-XXX.USFOEZPM.DPN	Netscalerda tüm gruplarda aynı session üzerinden ilerlemek için sticky session bilgisinin saklandığı cookiedir.	Session Cookie	Tarayıcı oturum süresi boyunca
<br></br>
Marketing	utmCampaign30dtemp2	Kullanıcı ziyaret kanalı kayıt bilgisini 30 gün tutmak	Persistent Cookie	1 Ay
<br></br>
Marketing	utmCampaignGO5d	Kullanıcı ziyaret kanalı kayıt bilgisini affiliate kampanyaları için 5 gün tutmak	Persistent Cookie	5 Gün
<br></br>
Marketing	utmCampaignLT30d	Kullanıcı ziyaret kanalı kayıt bilgisini 3 ay tutmak	Persistent Cookie	1 Ay
<br></br>
Marketing	utmMedium30dtemp2	Kullanıcı ziyaret kanalı kayıt bilgisini 30 gün tutmak	Persistent Cookie	1 Ay
<br></br>
Marketing	utmMediumGO5d	Kullanıcı ziyaret kanalı kayıt bilgisini affiliate kampanyaları için 5 gün tutmak	Persistent Cookie	5 Gün
<br></br>
Marketing	utmSource30dtemp2	Kullanıcı ziyaret kanalı kayıt bilgisini 30 gün tutmak	Persistent Cookie	1 Ay
<br></br>
Marketing	utmSourceGO5d	Kullanıcı ziyaret kanalı kayıt bilgisini affiliate kampanyaları için 5 gün tutmak	Persistent Cookie	5 Gün
<br></br>
				
				
4) Bugün Bendensin kişisel verileri hangi amaçlarla kullanıyor?
Bugün Bendensin , mevzuatın izin verdiği durumlarda ve ölçüde kişisel bilgilerinizi kaydedebilecek, saklayabilecek, güncelleyebilecek, üçüncü kişilere açıklayabilecek, devredebilecek, sınıflandırabilecek ve işleyebilecektir.
Kişisel verileriniz şu amaçlarla kullanılmaktadır:
Web sitesi/mobil uygulamalar üzerinden alışveriş yapanın/yaptıranın kimlik bilgilerini teyit etmek,
İletişim için adres ve diğer gerekli bilgileri kaydetmek,
Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun'un ilgili maddeleri tahtında akdettiğimiz sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili müşterilerimiz ile iletişime geçmek, gerekli bilgilendirmeleri yapabilmek,
Elektronik (internet/mobil vs.) veya kâğıt ortamında işleme dayanak olacak tüm kayıt ve belgeleri düzenlemek,
Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun'un ilgili maddeleri tahtında akdettiğimiz sözleşmeler uyarınca üstlenilen yükümlülükleri ifa etmek,
Kamu güvenliğine ilişkin hususlarda talep halinde ve mevzuat gereği kamu görevlilerine bilgi verebilmek,
Müşterilerimize daha iyi bir alışveriş deneyimini sağlamak, "müşterilerimizin ilgi alanlarını dikkate alarak" müşterilerimizin ilgilenebileceği ürünlerimiz hakkında müşterilerimize bilgi verebilmek, kampanyaları aktarmak,
Müşteri memnuniyetini artırmak, web sitesi ve/veya mobil uygulamalardan alışveriş yapan müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde kullanabilmek, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmek ve bu kapsamda anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anketler düzenlemek,
Anlaşmalı kurumlarımız ve çözüm ortaklarımız tarafından müşterilerimize öneri sunabilmek, hizmetlerimizle ilgili müşterilerimizi bilgilendirebilmek,
Hizmetlerimiz ile ilgili müşteri şikâyet ve önerilerini değerlendirebilmek,
<br></br>

Yasal yükümlülüklerimizi yerine getirebilmek ve yürürlükteki mevzuattan doğan haklarımızı kullanabilmek,
<br></br>

5) Bugün Bendensin kişisel verilerinizi nasıl koruyor?
Bugün Bendensin ile paylaşılan kişisel veriler, Bugün Bendensin gözetimi ve kontrolü altındadır. Bugün Bendensin , yürürlükteki ilgili mevzuat hükümleri gereğince bilginin gizliliğinin ve bütünlüğünün korunması amacıyla gerekli organizasyonu kurmak ve teknik önlemleri almak ve uyarlamak konusunda veri sorumlusu sıfatıyla sorumluluğu üstlenmiştir. Bu konudaki yükümlülüğümüzün bilincinde olarak veri gizliliğini konu alan uluslararası ve ulusal teknik standartlara uygun surette periyodik aralıklarda sızma testleri yaptırılmakta ve bu kapsamda veri işleme politikalarımızı her zaman güncellediğimizi bilginize sunarız.
<br></br>

6) Bugün Bendensin kişisel verilerinizi paylaşıyor mu?
Müşterilerimize ait kişisel verilerin üçüncü kişiler ile paylaşımı, müşterilerin izni çerçevesinde gerçekleşmekte ve kural olarak müşterimizin onayı olmaksızın kişisel verileri üçüncü kişilere aktarılmamaktadır.
Bununla birlikte, yasal yükümlülüklerimiz nedeniyle ve bunlarla sınırlı olmak üzere mahkemeler ve diğer kamu kurumları ile kişisel veriler paylaşılmaktadır. Ayrıca, taahhüt ettiğimiz hizmetleri sağlayabilmek ve verilen hizmetlerin kalite kontrolünü yapabilmek için anlaşmalı üçüncü kişilere kişisel veri aktarımı yapılmaktadır.
Üçüncü kişilere veri aktarımı sırasında hak ihlallerini önlemek için gerekli teknik ve hukuki önlemler alınmaktadır. Bununla birlikte, kişisel verileri alan üçüncü kişinin veri koruma politikalarından dolayı ve üçüncü kişinin sorumluluğundaki risk alanında meydana gelen ihlallerden Bugün Bendensin sorumlu değildir.
Kişisel verileriniz Bugün Bendensin hissedarlarıyla, doğrudan/dolaylı yurtiçi/yurtdışı iştiraklerimize, faaliyetlerimizi yürütebilmek için işbirliği yaptığımız program ortağı kurum, kuruluşlarla, verilerin bulut ortamında saklanması hizmeti aldığımız yurtiçi/yurtdışı kişi ve kurumlarla, müşterilerimize ticari elektronik iletilerin gönderilmesi konusunda anlaşmalı olduğumuz yurtiçi/yurtdışındaki kuruluşlarla, Bankalar arası Kart Merkeziyle, anlaşmalı olduğumuz bankalarla ve sizlere daha iyi hizmet sunabilmek ve müşteri memnuniyetini sağlayabilmek için çeşitli pazarlama faaliyetleri kapsamında yurtiçi ve yurtdışındaki çeşitli ajans, reklam şirketleri ve anket şirketleriyle ve yurtiçi/yurtdışı diğer üçüncü kişilerle ve ilgili iş ortaklarımızla paylaşılabilmektedir.
<br></br>

7) Kişisel Verilerin Korunması Kanunu'ndan doğan haklarınız nelerdir?<br></br>

KVKK uyarınca kişisel verilerinizin;
<br></br>
a) işlenip işlenmediğini öğrenme,
<br></br>
b) işlenmişse bilgi talep etme,
<br></br>
c) işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,
<br></br>
d) yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme,
<br></br>
e) eksik / yanlış işlenmişse düzeltilmesini isteme,
<br></br>
f) KVKK'nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme,
<br></br>
g) aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme,
<br></br>
h) münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
<br></br>
i) KHK’ya aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde zararın giderilmesini talep etme haklarına sahip olduğunuzu hatırlatmak isteriz.
<br></br>
j) Başvuru formu için tıklayınız.
<br></br>

8) Kişisel verilerle ilgili mevzuat değişikliklerinden nasıl haberdar olabilirim?
KVKK uyarınca sahip olduğunuz haklar Bugün Bendensin ‘in yükümlülükleridir. Kişisel verilerinizi bu bilinçle ve mevzuatın gerektirdiği ölçüde işlediğimizi, yasal değişikliklerin olması halinde sayfamızda yer alan bu bilgileri yeni mevzuata uygun güncelleyeceğimizi, yapılan güncellemeleri de bu sayfa üzerinden her zaman kolaylıkla takip edebileceğinizi size bildirmek isteriz.
<br></br>

9) Verinin güncel ve doğru tutulduğundan nasıl emin olabilirim?
KHK’nın 4. maddesi uyarınca Bugün Bendensin ’in kişisel verilerinizi doğru ve güncel olarak tutma yükümlülüğü bulunmaktadır. Bu kapsamda Bugün Bendensin ‘in yürürlükteki mevzuattan doğan yükümlülüklerini yerine getirebilmesi için müşterilerimizin Bugün Bendensin ile doğru ve güncel verilerini paylaşması gerekmektedir. Verilerinizin herhangi bir surette değişikliğe uğraması halinde aşağıda belirtilen iletişim kanallarından bizimle iletişime geçerek verilerinizi güncellemenizi rica ederiz.
<br></br>

10) Bugün Bendensin’e kişisel verilerinizle ilgili soru sormak ister misiniz?
Bize kişisel verilerinizle ilgili her türlü soru ve görüşleriniz için kvkk@bugunbendensin.com e-posta adresinden dilediğiniz zaman ulaşabilirsiniz.
<br></br>

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    Kapat
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import AppButton from "../components/AppButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import PrivacyPolicy from "./Modals/PrivacyPolicy";
import { Link } from "react-router-dom";
import { useState } from "react";
import Terms from "./Modals/Terms";
import Cookie from "./Modals/Cookie";
import Tedarikci from "./Modals/Tedarikci";
import { useTranslation } from 'react-i18next';
export default function Footer() {
  const { t } = useTranslation();
  const [privacy, setPravicy] = useState(false);
  const [terms, setTerms] = useState(false);
  const [cookie, setCookie] = useState(false);
  return (
    <>
      <footer>
        <div className="bg-primary-yellow">
          <div className="py-10 mx-auto grid grid-cols-2 md:grid-cols-5 gap-4 max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mt-6 col-span-2 md:col-span-1">
              <img src="/logo_web_footer.png" alt="logo" />
            </div>
            <div>
              <h3 className="text-2c2c2c font-semibold text-lg mb-2">
                {t('footer.column_one.title')}
              </h3>
              
              <div className="mt-2">
            
                <Link
                  className="text-2c2c2c font-light text-sm"
                  to="/hakkimizda"
                >
                  {t('footer.column_one.one')}
                </Link>
              </div> 
              {/* <div className="mt-2">
                <a
                  className="text-2c2c2c font-light text-sm"
                  href="/hakkimizda"
                >
                  {t('footer.column_one.one')}
                </a>
              </div> }
              {/* <div className="mt-2">
                <a className="text-2c2c2c font-light text-sm" href="!#">
                  Company Information
                </a>
              </div>
              <div className="mt-2">
                <a className="text-2c2c2c font-light text-sm" href="!#">
                  Company Policy
                </a>
              </div> */}
            </div>
            <div>
              <h3 className="text-2c2c2c font-semibold text-lg mb-2">{t('footer.column_two.title')}</h3>
              <div className=" mt-2">
                <button
                  onClick={() => {
                    setPravicy(true);
                  }}
                  className="text-2c2c2c font-light text-sm"
                >
                  {t('footer.column_two.one')}{/* Privacy Policy */}
                </button>
              </div>
              {/* <div className="mt-2">
                <button
                  className="text-2c2c2c font-light text-sm"
                  onClick={() => {
                    setTerms(true);
                  }}
                >
                  {t('footer.column_two.two')} { Terms & Conditions }
                </button>
              </div> */}
              <div className="mt-2">
                <button
                  className="text-2c2c2c font-light text-sm"
                  onClick={() => {
                    setCookie(true);
                  }}
                >
                  {t('footer.column_two.three')}{/* Cookie Policy */}
                </button>
              </div>
              {/* <div className="mt-2">
                <button
                  className="text-2c2c2c font-light text-sm"
                  onClick={() => {
                    setCookie(true);
                  }}
                >
                  Tedarikçi Sözleşmesi
                </button>
              </div> */}
            </div>
            <div>
              <h3 className="text-2c2c2c font-semibold text-lg mb-2">
                {t('footer.column_three.title')}{/* Contact */}
              </h3>
              <div className="mt-2">
                <Link className="text-2c2c2c font-light text-sm" to="/sss">
                  {t('footer.column_three.one')}{/* F.A.Q */}
                </Link>
              </div>
              
              <div className="mt-2">
                <Link className="text-2c2c2c font-light text-sm" to="/iletisim">
                  {t('footer.column_three.three')}{/* Contact us */}
                </Link>
              </div>
              <div className="mt-4 md:hidden">
              <a href="https://www.instagram.com/bugunbendensin" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon
                  className="text-4xl text-zinc-800 mr-4"
                  icon={faInstagram}
                /></a>

                <a href="https://www.facebook.com/people/bugunbendensin/61550552104891/" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon
                  className="text-4xl text-zinc-800 mr-4"
                  icon={faFacebook}
                /></a>
                <a href="https://www.twitter.com/bugun_bendensin" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon
                  className="text-4xl text-zinc-800 mr-4"
                  icon={faTwitter}
                /></a>
                <a href="https://www.linkedin.com/company/97840537/admin/feed/posts/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon
                  className="text-4xl text-zinc-800"
                  icon={faLinkedin}
                /></a>
                <a href="https://www.linkedin.com/company/97840537/admin/feed/posts/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon
                  className="text-4xl text-zinc-800"
                  icon={faTiktok}
                /></a>

              </div>
            </div>
            <div>
              <h3 className="text-2c2c2c font-semibold text-lg mb-2">
                {t('footer.column_four.title')}
              </h3>
              <div className="mt-2" >
                <AppButton icon="apple" />
              </div>
              <div className="mt-2">
                <AppButton />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="py-6 items-center mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="text-center md:text-left">
              © 2022 <a href="/">HABBTECH. Tüm hakları saklıdır.</a>
            </div>
            <div className="text-right mt-4 hidden md:block">
            <a href="https://www.instagram.com/bugunbendensin" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon
                  className="text-4xl text-zinc-800 mr-4"
                  icon={faInstagram}
                /></a>
              <a href="https://www.facebook.com/people/bugunbendensin/61550552104891/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon
                className="text-4xl text-zinc-800 mr-4"
                icon={faFacebook}
              /></a>
                <a href="https://www.tiktok.com/@bugunbendensin?_t=8fywdu490Lt&_r=1" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon
                  className="text-4xl text-zinc-800"
                  icon={faTiktok}
                /></a>
                    
              <a href="https://www.twitter.com/bugun_bendensin" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon
                className="text-4xl text-zinc-800 mr-4"
                icon={faTwitter}
              /></a>
              <a href="https://www.linkedin.com/company/bug%C3%BCn-bendensin/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon
                  className="text-4xl text-zinc-800"
                  icon={faLinkedin}
                /></a>
              
            </div>
          </div>
        </div>
        <PrivacyPolicy
          visible={privacy}
          onClose={() => {
            setPravicy(false);
          }}
        />
        <Terms
          visible={terms}
          onClose={() => {
            setTerms(false);
          }}
        />
        <Cookie
          visible={cookie}
          onClose={() => {
            setCookie(false);
          }}
        />
      </footer>
    </>
  );
}



import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import "swiper/css";
SwiperCore.use([Navigation]);
export default function HomeSliderVertical() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  return (
    <>
      <Swiper
        initialSlide="2"
        direction={"vertical"}
        className="w-full !h-[300px] !overflow-visible"
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
      >
        <SwiperSlide className="p-6 !h-auto bg-review bg-no-repeat bg-center bg-cover border-4 mb-10 backdrop-blur-3xl border-white drop-shadow-lg rounded-3xl">
          <p className="text-dark-main text-lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit
            habitasse posuere diam sed nisl,{" "}
          </p>
          <h3 className="text-2c2c2c font-semibold mt-4">Dwight Schrute</h3>
        </SwiperSlide>
        <SwiperSlide className="p-6 !h-auto bg-review bg-no-repeat bg-center bg-cover border-4 mb-10 backdrop-blur-3xl border-white drop-shadow-lg rounded-3xl">
          <p className="text-dark-main text-lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit
            habitasse posuere diam sed nisl,{" "}
          </p>
          <h3 className="text-2c2c2c font-semibold mt-4">Dwight Schrute</h3>
        </SwiperSlide>
        <SwiperSlide className="p-6 !h-auto bg-review bg-no-repeat bg-center bg-cover border-4 mb-10 backdrop-blur-3xl border-white drop-shadow-lg rounded-3xl">
          <p className="text-dark-main text-lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit
            habitasse posuere diam sed nisl,{" "}
          </p>
          <h3 className="text-2c2c2c font-semibold mt-4">Dwight Schrute</h3>
        </SwiperSlide>
        <SwiperSlide className="p-6 !h-auto bg-review bg-no-repeat bg-center bg-cover border-4 mb-10 backdrop-blur-3xl border-white drop-shadow-lg rounded-3xl">
          <p className="text-dark-main text-lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit
            habitasse posuere diam sed nisl,{" "}
          </p>
          <h3 className="text-2c2c2c font-semibold mt-4">Dwight Schrute</h3>
        </SwiperSlide>
        <SwiperSlide className="p-6 !h-auto bg-review bg-no-repeat bg-center bg-cover border-4 mb-10 backdrop-blur-3xl border-white drop-shadow-lg rounded-3xl">
          <p className="text-dark-main text-lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit
            habitasse posuere diam sed nisl,{" "}
          </p>
          <h3 className="text-2c2c2c font-semibold mt-4">Dwight Schrute</h3>
        </SwiperSlide>
        <div className="absolute inline-grid z-50 -bottom-46 -right-16">
          <button ref={navigationNextRef}>
            <ChevronUpIcon className="h-12 w-12 bg-white text-fuchsia-500 rounded-md mr-2" />
          </button>
          <button className="mt-2" ref={navigationPrevRef}>
            <ChevronDownIcon className="h-12 w-12 bg-white text-fuchsia-500  rounded-md" />
          </button>
        </div>
      </Swiper>
    </>
  );
}

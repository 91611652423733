import CompaignSlider from "../components/CampaignSlider";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {useTranslation} from "react-i18next";
export default function Compaigns() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="kampanya-sh-1 absolute h-[100vh] w-full"></div>
      <div className="relative bg-primary-linear-0 overflow-hidden">
        <div className="max-w-5xl mx-auto pb-28">
          <div className="grid grid-cols-3">
            <div className="col-span-2 mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-white text-4xl font-semibold">
                  {t('campaigns.title')}
                </h1>
                <p className="mt-3 text-xl text-white">
                  {t('campaigns.description')}
                </p>
              </div>
            </div>
            <div className="col-span-1">
              <img
                className="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full"
                src="/compaigns-head.png"
                alt=""
              />
            </div>
          </div>
          <div className="w-full px-6 mt-14 md:mt-0">
            <CompaignSlider />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";

export default function CampaignSlider() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={50}
        className=""
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
      >
        <SwiperSlide>
          <div>
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
            <Card
              index={4}
              src="/campaignthumb.png"
              p="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ac, ipsum eget nulla cursus sagittis. Amet accumsan, neque tellus gravida suspendisse. Etiam urna massa fermentum pellentesque dui dolor gravida. "
            />
          </div>
        </SwiperSlide>
        <div className="flex mt-5 justify-end">
          <button ref={navigationPrevRef}>
            <ChevronLeftIcon className="h-12 w-12 bg-white text-fuchsia-500 rounded-md mr-2" />
          </button>
          <button ref={navigationNextRef}>
            <ChevronRightIcon className="h-12 w-12 bg-white text-fuchsia-500  rounded-md" />
          </button>
        </div>
      </Swiper>
    </>
  );
}

const Card = ({ index, src, p }) => (
  <div className="relative p-4 mb-8 bg-zinc-50/80 rounded-3xl shadow-md flex justify-center items-center">
    <img className="max-w-[144px]" width={"100%"} src={src} alt="1" />
    <div className="ml-3">
      <h2 className="font-semibold text-lg text-dark-main">
        Tellus posuere fermentum aliquam facilisi porta.
      </h2>
      <p className="text-md mt-3">{p}</p>
    </div>
  </div>
);

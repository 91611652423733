import Header from "../components/Header";
import AppButton from "../components/AppButton";
import HorizontalSlider from "../components/HomeSlider";
import "../styles.css";
import HomeSlider2 from "../components/HomeSlider2";
import HomeSliderVertical from "../components/HomeSliderVertical";
import { Link } from "react-router-dom";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuoteLeftAlt,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";

import { useTranslation } from 'react-i18next';
export default function Home() {
  const { t } = useTranslation();
  return (
    <>
      <Header />

      <div className="relative bg-home-linear overflow-hidden">
        <div className="home-shapes-1">
          <div className="max-w-7xl mx-auto">
            <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-xl lg:w-full lg:pb-28 xl:pb-32">
              <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-left">
                  <h1 className="text-4xl tracking-tight font-semibold text-white sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-tight">
                    <span className="block xl:inline">
                      {t('home.section_one.title')}</span>
                  </h1>
                  <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                    {t('home.section_one.description')}
                  </p>
                  <div className="mt-5 inline-block sm:mt-20 sm:flex sm:justify-center lg:justify-start">
                    <div>
                      <AppButton icon="apple" theme={3} />
                      
                    </div>
                    
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                      <AppButton theme={3} href="/kampanyalar" />
                    </div>
                  </div>
                  
                  <div><img
              className=" lg:h-full mt-5 inline-block sm:mt-20 sm:flex sm:justify-center lg:justify-start"
              src="/QR.png"
              alt=""
            /></div>
                </div>
              </main>
            </div>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full"
              src="/homeSection2.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="bg-primary-main">
        <div className="max-w-7xl mx-auto">
          <div className="pt-10 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-44">
            <div className="sm:text-center lg:text-left">
              <h2 className="text-2xl tracking-tight font-semibold text-white sm:text-3xl sm:tracking-tight md:text-4xl md:tracking-tight">
                <span className="block xl:inline">{t('home.section_two.title')}</span>
              </h2>
              <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                {t('home.section_two.description')}
              </p>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto">
          <HorizontalSlider />
        </div>
        <div className="homeSlider2bg mt-5 py-16 ">
          <div className="home-shapes-4">
            <div className="max-w-7xl mx-auto relative">
              <span className="home-shapes-3"></span>
              {/* <h2 className="font-semibold text-4xl text-2c2c2c text-center mb-10">
                {t('home.section_three.title')}
              </h2> */}
              <HomeSlider2 />
            </div>
          </div>
        </div>
        {/* <div className="overflow-hidden home-shapes-5">
          <div className="bg-white py-20 ">
            <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 px-16 md:px-4">
              <div className="flex justify-center">
                <img
                  className="h-[400px] lg:h-[564px] 2xl:h-[683px]"
                  src="/iPhoneX.jpg"
                  alt="iphone"
                />
              </div>

              <div className="mt-8 md:mt-0">
                <h2 className="text-2c2c2c font-semibold text-4xl mb-4">
                  Sed tempor dolor.
                </h2>
                <p className="text-2c2c2c text-lg">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Pellentesque vitae mauris magna nisl nullam cras. In vel et
                  sed eu. Suspendisse vestibulum eget nunc gravida congue.
                  Libero a aliquet odio in cras interdum eu id enim.{" "}
                </p>
              </div>
              <div>

              </div>
            </div>
          </div>

          <div className="py-10 mx-auto grid grid-cols-1 md:grid-cols-3 home-shapes-6 gap-4 max-w-7xl px-4 sm:py-12 sm:px-6 md:py-16 lg:py-20 lg:px-8 xl:py-44">
            <div className="sm:text-center lg:text-left col-span-3 xl:col-span-2">
              <h2 className="text-2xl tracking-tight font-semibold text-white sm:text-3xl sm:tracking-tight md:text-4xl md:tracking-tight">
                <span className="block xl:inline">Kullanıcılarımız</span>
              </h2>
              <p className="mt-3 text-xl text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                <FontAwesomeIcon
                  className="text-2xl mr-2"
                  icon={faQuoteLeftAlt}
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                enim at id semper nibh. Id a, nunc, accumsan in aenean quis ac
                lectus. Eu, commodo mattis sit blandit morbi. Praesent
                fermentum.
                <FontAwesomeIcon
                  className="text-2xl ml-2"
                  icon={faQuoteRight}
                />
              </p>
              <h3 className="text-xl text-white mt-6">Andrew Bernard</h3>
            </div>
            <div className="hidden xl:block">
              <HomeSliderVertical />
            </div>
          </div>
        </div> */}
      </div>
      <div className="homeSlider2bg pt-32 py-20 home-shapes-7">
        <div className="max-w-5xl mx-auto px-4 sm:px-6  lg:px-8">
          <h2 className="font-semibold text-4xl text-2c2c2c text-center mb-16">
            {t('home.section_sss.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
            <div>
              <div>
                <h3 className="font-semibold text-lg text-2c2c2c mb-3">
                  {t('home.section_sss.one.title')}
                </h3>
                <p>
                  {t('home.section_sss.one.description')}
                </p>
              </div>
              <div className="mt-8">
                <h3 className="font-semibold text-lg text-2c2c2c mb-3">
                  {t('home.section_sss.two.title')}
                </h3>
                <p>
                  {t('home.section_sss.two.description')}
                </p>
              </div>
            </div>
            <div>
              <div>
                <h3 className="font-semibold text-lg text-2c2c2c mb-3">
                  {t('home.section_sss.three.title')}
                </h3>
                <p>
                  {t('home.section_sss.three.description')}
                </p>
              </div>
              <div className="mt-8">
                <h3 className="font-semibold text-lg text-2c2c2c mb-3">
                  {t('home.section_sss.four.title')}
                </h3>
                <p>
                  {t('home.section_sss.four.description')}
                </p>
              </div>
            </div>
          </div>
          <div className="text-center mt-10 xl:mt-20">
          <Link
                  className="border rounded-lg border-primary-main text-primary-main px-4 py-2"
                  to="/sss"
                >
                  {t('home.section_sss.see_all_btn')}
                </Link>
           {/*  <a
              href="/sss"
              className="border rounded-lg border-primary-main text-primary-main px-4 py-2"
            >
              {t('home.section_sss.see_all_btn')}
            </a> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
